import './Success.css';
import SuccessCloseButton from '../../assets/images/close-button.svg';
import watercolorBackground from '../../assets/images/watercolor.png';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';
import ReactPlayer from 'react-player';

function Success() {

  const success = useRef();
  const successVideo = useRef();
  const successVideoVideo = useRef();
  const closeSuccessButton = useRef();
  const watercolorBackgroundRef = useRef();

  const urls =[
    'https://vimeo.com/615347613/da1ca0dd1d', // cluster room
    'https://vimeo.com/615371870/1bd4f91333', // leature room
    'https://vimeo.com/615372017/deb73649bc', // team room
  ]
  
  const [url, setURL] = useState(urls[0]);

  const [playingSuccessVideo, setPlayingSuccessVideo] = useState(false);

  useEffect(() => {
    eventBus.on("showSuccess", (data) =>{
      // console.log("show success", data)
      
      /////////////////////////////////////////////////////////////////////////////////

      window.view = "success";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////


      setURL(urls[data.room-1]);
      // console.log(url)
      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");
      const tl = gsap.timeline({paused:true});
      tl.to([success.current],{ autoAlpha:1, duration:.5},.3);
      tl.fromTo([successVideo.current],{autoAlpha:0}, { autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        setPlayingSuccessVideo(true);
        window.Howler.mute(true);
      },null,0)
      tl.to([ closeSuccessButton.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      eventBus.dispatch("restoreNormalSideBarText",{});
      eventBus.dispatch("showCloseButtonMobile",{});
    });

    eventBus.on("hideSuccess", data =>{
      // console.log("hide success");
      const tl = gsap.timeline({paused:true});
      tl.to([success.current],{ autoAlpha:0, duration:.5},0);
      tl.to([ closeSuccessButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("showMobileContent",{});
        setPlayingSuccessVideo(false);
        if(!window.muted){
          window.Howler.mute(false);
        }
      },null,0)
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      tl.play();
      
      eventBus.dispatch("hideCloseButtonMobile",{});
      // eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
    })

    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="success"){
        eventBus.dispatch("hideSuccess");
      }
    });

    eventBus.on("hideMobileContent", data =>{
      const tl = gsap.timeline({paused:true});
      tl.play();
    });

  }, [url])

  const closeSuccessButtonClicked = () =>{
    eventBus.dispatch("hideSuccess");
    
  }

  const videoEnded =()=>{
    eventBus.dispatch("hideSuccess");
  }
  
  
  return (
      <div className={'success'} ref={success}>
        <div className="success-background"></div>
        <div className={"success-video"} ref={successVideo}>
          <ReactPlayer
            ref={successVideoVideo}
            playsinline ={true}
            muted={false}
            playing ={playingSuccessVideo}
            loop={false}
            width={"100%"}
            height={"100%"}
            className="success-video-video" 
            controls={true}
            url={url}
            onEnded = {videoEnded}
          />
        </div>
        <img ref={watercolorBackgroundRef} className="success-watercolor-background" src={watercolorBackground} alt="" />
         <img ref={closeSuccessButton}  onClick={closeSuccessButtonClicked} className="success-close-button" src={SuccessCloseButton} />
      </div>
  );
}

export default Success;
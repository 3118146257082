import './Key.css';
import KeyCloseButton from '../../assets/images/close-button.svg';
// import LeftArrow from '../../assets/images/left-arrow.png';
// import RightArrow from '../../assets/images/right-arrow.png';
// import imageBackground from '../../assets/images/watercolor.png';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';
import ReactPlayer from 'react-player';

import KeyVideo from '../../assets/videos/key.mp4'
// import Confetti from 'react-confetti'
import { useWindowSize} from '@react-hook/window-size'

function Key() {
  // const [showConfetti , setShowConfetti ] = useState(false)
  const [width, height] = useWindowSize()
  const images = useRef();
  const closeKeyButton = useRef();
  const rightArrow = useRef();
  const leftArrow = useRef();

  const keyVideoRef = useRef();
  const keyVideoVideoRef = useRef();
  const title=useRef();
  const subtitle=useRef();

  const [playingKeyVideo,setPlayingKeyVideo] = useState(false);
  // const [cardIndex,setCardIndex] = useState(0);

  // const cards = [
  //   {src:"./assets/images/dean/image-1.png"},
  // ]

  // useEffect(()=>{
  //   showNextCard();
  // },[cardIndex])
  
  /////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    eventBus.on("showKey", (data) =>{
      // console.log("show key", data)

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "key";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////
      
      window.unlocked = true;
      // console.log(window.view)

      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");

      gsap.killTweensOf([images.current, closeKeyButton.current, subtitle.current, title.current]);

      keyVideoVideoRef.current.seekTo(0);
      setPlayingKeyVideo(false);

      const tl = gsap.timeline({paused:true});
      tl.set([subtitle.current,title.current],{scale:1.2, autoAlpha:0},0);
      tl.to([images.current],{ autoAlpha:1, duration:.5},.3);
      tl.to([closeKeyButton.current],{ autoAlpha:1, duration:.5},.3);
      tl.to([subtitle.current,title.current],{ stagger:.5, autoAlpha:1, scale:1, duration:2},4);
      tl.call(()=>{
        setPlayingKeyVideo(true);
      },null,.5)
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      // tl.call(()=>{
      //   setShowConfetti(true)
      // },null,4)
      tl.play();
      
      //
      
      eventBus.dispatch("restoreNormalSideBarText",{});
      eventBus.dispatch("showCloseButtonMobile",{});
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideKey", data =>{
      // console.log("hide key");

      gsap.killTweensOf([images.current, closeKeyButton.current]);

      const tl = gsap.timeline({paused:true});
      tl.to([images.current],{ autoAlpha:0, duration:.5},0);
      tl.to([closeKeyButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      // tl.call(()=>{
      //   setShowConfetti(false);
      // },null,3)
      tl.play();

      //

      eventBus.dispatch("hideCloseButtonMobile",{});
      eventBus.dispatch("disableNormalSideBarText",{});
    })

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="key"){
        eventBus.dispatch("hideKey");
      }
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideMobileContent", data =>{
      const tl = gsap.timeline({paused:true});
      tl.play();
    });


  }, [])

  // const showNextCard = ()=>{
  //   const tl = gsap.timeline({paused:true});
  //   tl.to('.key-card',{autoAlpha:0},0);
  //   tl.to('.key-card-'+ cardIndex, {autoAlpha:1});
  //   tl.play();

  //   // update the bottom nav


  // }


  const closeKeyButtonClicked = () =>{
    eventBus.dispatch("hideKey");
    
  }

  // const rightClicked = () =>{
   
  //   setCardIndex(  (cardIndex+cards.length+1) %  cards.length);  
    
  // }

  // const leftClicked = () =>{
   
  //   setCardIndex(  (cardIndex+cards.length-1) %  cards.length);  
    
  // }

  const username = (window.username)? window.username : "Your Name Here";

  return (
      <div className={'key'} ref={images}>
        <div className={"key-video"} ref={keyVideoRef}>
          <ReactPlayer
            ref = {keyVideoVideoRef}
            playsinline ={true}
            muted={true}
            playing ={playingKeyVideo}
            loop={false}
            width={"100%"}
            height={"100%"}
            className="key-video-video" 
            url={KeyVideo}
            //onEnded = {videoEnded}
          />
        </div>
        <div className="key-subtitle" ref={subtitle}>
        Made possible by a generous gift from
        </div>
        <div className="key-title" ref={title}>
        {username}
        </div>
        {/* <div className ="key-cards">
            {cards.map((c,i)=>(
                <div key={i} className={`key-card key-card-${i}`}>
                  <img src={c.src} className="key-card-image" alt="" />
                  <img src={imageBackground} className="key-image-background" alt="" />
                </div>
            )
        )}
        </div> */}
       
         <img ref={closeKeyButton}  onClick={closeKeyButtonClicked} className="key-close-button" src={KeyCloseButton} />
         {/* <div className="key-nav">
            <div className="key-nav-numbers">
            {cardIndex+1} of {cards.length}
            </div>
            <div className="key-nav-text">
            &lt;&lt; CLICK TO EXPLORE &gt;&gt;
            </div>
         </div> */}
         {/* { showConfetti && 
         <Confetti
            className="thanks-confetti"
            width={width}
            height={height}
            gravity={.01}
            numberOfPieces={100}
            colors={['#0f5fbe','#d70c4c' ]}
          />
         } */}
      </div>
  );
}

export default Key;
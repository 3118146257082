import './Enter.css';

import HorseLogo from '../../assets/images/horse-logo.svg';
import Logo from '../../assets/images/smu-logo.png';
import Background from '../../assets/images/background.jpg';

import React,{ useEffect, useRef} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

import ambientLoop from '../../assets/audio/ambient-smu.mp3'

import ReactHowler from 'react-howler'

function Enter() {
  
  const enter = useRef(); 
  const ambientLoopRef = useRef();
  
  useEffect(() => {
    eventBus.on("hideEnter",(data) =>{
      // console.log("hide enter")
      const tl = gsap.timeline({paused:true});
      tl.to(enter.current,{autoAlpha:0,duration:2},(data.delay)?data.delay:0);
      tl.play();
    })
  
    eventBus.on("showEnter",(data) =>{
      
      /////////////////////////////////////////////////////////////////////////////////

      // window.history.pushState({},'','./intro');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': 'websiteLoaded',
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / websiteLoaded");

      },100)

      /////////////////////////////////////////////////////////////////////////////////
      
      //console.log("show enter")
      const tl = gsap.timeline({paused:true});
      tl.to(enter.current,{autoAlpha:1},(data.delay)?data.delay:1);
      tl.play();

      // ambientLoopRef.current.play();

    })

    eventBus.on("showVideo",(data) =>{
      // console.log("showVideo / window.muted = " + window.muted);
      window.Howler.mute(true);
    });

    eventBus.on("showLanding",(data) =>{
      // console.log("showLanding / window.muted = " + window.muted);

      if(!window.muted) {
        window.Howler.mute(false);
      }

      ambientLoopRef.current.play();
      
    });

  }, [])

  
  const enterClicked = (e) =>{
    eventBus.dispatch("hideEnter", {delay:0});
    eventBus.dispatch("showVideo", {delay:2});

    // in case brwoser needs click to start sound
    // ambientLoopRef.current.play();
  }

  return (
      <div ref={enter} className="enter">
        <img src={Background} className="enter-background" alt="" />
        <img src={Logo} className="enter-logo" alt="" />
        <p className="enter-message">Cox renovation and expansion project.</p>
        <button onClick={enterClicked} className="enter-button">Enter Site <img src={HorseLogo} alt="" /></button>
        <ReactHowler ref={ambientLoopRef} src={ambientLoop} playing={false} loop={true} volume={.1} />
      </div>

  );
}

export default Enter;
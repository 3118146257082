import './SideBar.css';
import Logo from '../../assets/images/logo.png';
import SideBarBase from '../../assets/images/side-bar-base.jpg';
import DonorsButton from '../../assets/images/donors-button.svg';
import DonorsButtonMobile from '../../assets/images/donor-mobile.svg';
import LearnMoreButton from '../../assets/images/learn-more-button.svg';
import LearnMoreButtonMobile from '../../assets/images/info-mobile.svg';
import HorseLogo from '../../assets/images/horse-logo-red.svg';
import SideBarCloseButton from '../../assets/images/close-button.svg';
import SideBarBackButton from '../../assets/images/back-button.png';
import mobileModalBack from '../../assets/images/mobile-modal.png';
import modalLogo from '../../assets/images/modal-logo.png';
import modalExploreButton from '../../assets/images/modal-explore-button.png';
import watercolorBackground from '../../assets/images/watercolor.png';

import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";

import gsap from 'gsap';

function SideBar() {

  const sideBar = useRef();
  const sideBarBase = useRef();
  const logo = useRef();
  const sideBarTitleEl = useRef();
  const sideBarTextEl = useRef();
  const donorsButton = useRef();
  const donorsButtonMobile = useRef();
  const learnMoreButton = useRef();
  const learnMoreButtonMobile = useRef();
  const backSideBarButton = useRef();
  const closeSideBarButton = useRef();
  const closeButtonMobile = useRef();
  const horseLogo = useRef();
  const mobileContentEl = useRef();
  const watercolorBackgroundRef = useRef();



  const _landingTitle = ()=>{return(<><i>Boldly Shaping Tomorrow</i></>)};
  const _landingText = ()=>{return(<>As the premier private university in North Texas, SMU exists to shape future leaders who transform communities and organizations through ingenuity and thoughtful problem-solving.</>)};

  const _donorsTitle = ()=>{return(<>Donors</>)};
  const _donorsText = ()=>{return(<><h1>Thank You</h1>SMU Cox is grateful for the generosity of our alumni and supporters. With their help, the Cox School will continue expanding our influence nationally and internationally.</>)};

  const _rotundaTitle = ()=>{return(<>Rotunda</>)};
  const _rotundaText = ()=>{return(<>For over 100 years, business education at SMU has shaped world changers and created a community of diverse thinkers and industry leaders. With the help of supporters like you, we will continue to fuel the future of business education at Cox School and empower Mustangs’ entrepreneurial spirit for generations to come.</>)};

  const _galleryTitle = ()=>{return(<>Image Gallery</>)};
  const _galleryText = ()=>{return(<>
  Please enjoy your virtual tour of the newly renovated Cox School of Business. These renderings highlight key areas across the school, giving you greater insight into our future business complex.</>)};

  const _deanTitle = ()=>{return(<><span className="side-bar-donor-text"><i className="side-bar-small-text">Message from the Dean of the Cox School of Business,</i><br />Matthew B. Myers</span></>)};
  const _deanText = ()=>{return(<></>)};

  const _donorTitle = ()=>{return(<><span className="side-bar-donor-text"><i className="side-bar-small-text">"Message from lead project donor, SMU Trustee and Cox Executive Board Chair David B. Miller </i><br />David B. Miller</span></>)};
  const _donorText = ()=>{return(<></>)};


  const _room1Title = ()=>{return(<>Cluster Classroom</>)};
  const _room1Text = ()=>{return(<>Tiered classrooms are ideal for lecture-style or case study instruction. The curved rows of fixed desktops ensure optimal sight lines to the instructor and audio-visual presentations, while maintaining visibility from student to student to enhance full-class, peer-to-peer discussion.</>)};
  const _room2Title = ()=>{return(<>Lecture Hall</>)};
  const _room2Text = ()=>{return(<>The lecture classroom captures many of the benefits of the case study classroom but is designed to facilitate students working in groups without the disruption of leaving the classroom. Flexible seating allows all students to face the front of the room or to turn and face their group table, encouraging collaboration.</>)};
  const _room3Title = ()=>{return(<>Team Rooms</>)};
  const _room3Text = ()=>{return(<>As project- and team-based exercises become increasingly important parts of the business school curriculum, team rooms provide a place where students can brainstorm, plan and collaborate. Doubling after hours as semi-private study and workspace locations, team rooms are often the most frequently used spaces in the building.</>)};

  const _success1Title = ()=>{return(<>The Cox Connection</>)};
  const _success1Text = ()=>{return(<>Cox School fosters connections that turn into lifelong opportunities. Students might begin the year sitting next to a stranger who ends up becoming a trusted business associate. That’s what happened with BBA 2001 alumni Brian Sheffield, Ryan Dalton and Colin Roberts, who came together later after SMU to take public the company Brian founded.</>)};

  const _success2Title = ()=>{return(<>Ally for business</>)};
  const _success2Text = ()=>{return(<>Students come to Cox School seeking a business degree to accelerate the achievement of their career goals. Comprising a diverse range of backgrounds with a broad array of dreams, they come together to discover their potential, tap into resources they never knew existed and forge their own paths to success.</>)};

  const _success3Title = ()=>{return(<>Entrepreneurial spirit</>)};
  const _success3Text = ()=>{return(<>Until a decade ago, not many people were familiar with the concept of cryptocurrency. Through the course of their respective four-year BBA degree programs, Hamiz Mustaq Awan ’17 and Richard Raizes ’13, ultimately came together to create what has become Plutus 21, a thriving, global cryptocurrency finance firm.</>)};

  const _keyTitle = ()=>{return(<>You’re the key</>)};
  const _keyText = ()=>{return(<>By supporting SMU’s Cox School classrooms, you are empowering the next generation of business leaders to meet grand challenges with bold and creative innovation.< br />< br />For more information, please contact Ashley Pitts at <a href="mailto:apitts@smu.edu"  target='_blank'>apitts@smu.edu</a>.</>)};

  // Donor opportunities start at $1 million. 

  const _discoverTitle = ()=>{return(<>Discover Our Future</>)};
  const _discoverText = ()=>{return(<></>)};


  
  
  const [sideBarTitle, setSideBarTitle] = useState(_landingTitle);
  const [sideBarText, setSideBarText] = useState(_landingText);


  useEffect(() => {
    window.returnPath = "landing";
    
    eventBus.on("showSideBar", (data) =>{
      // console.log("show side bar")

      gsap.killTweensOf([sideBar.current, logo.current, donorsButton.current, learnMoreButton.current, horseLogo.current, sideBarTitleEl.current, sideBarTextEl.current, donorsButtonMobile.current, learnMoreButtonMobile.current]);

      const tl = gsap.timeline({paused:true});
      tl.to(sideBar.current,{x:0,y:0, duration:1},0);
      //eventBus.dispatch("updateSideBarContent",{path:"landing",delay:2});
      if(window.innerWidth<window.innerHeight){
        tl.set([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current, horseLogo.current],{ autoAlpha:0,},0);
        tl.to([logo.current,donorsButton.current, learnMoreButton.current,horseLogo.current],{ stagger: 0.08, autoAlpha:1},0);
        tl.to([sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1},.5);
      } else {
        tl.set([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current, horseLogo.current],{ autoAlpha:0, x:-100},0);
        tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current,horseLogo.current],{ stagger: 0.08, autoAlpha:1, x:0},0);
      }
      
      tl.set([ donorsButtonMobile.current, learnMoreButtonMobile.current],{ autoAlpha:0, },0);
      tl.to([donorsButtonMobile.current, learnMoreButtonMobile.current],{ stagger: 0.08, autoAlpha:1,},0);
      tl.play();
    });

    eventBus.on("hideSideBar", (data) =>{
      // console.log("hide side bar")

      gsap.killTweensOf([sideBar.current]);

      const tl = gsap.timeline({paused:true});
      tl.to(sideBar.current,{x:"-30%",duration:1},0);
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("learnMoreButtonClicked", (data) =>{
      
      eventBus.dispatch("enableClickBlocker");

      gsap.killTweensOf([sideBar.current, logo.current, donorsButton.current, learnMoreButton.current, horseLogo.current, sideBarTitleEl.current, sideBarTextEl.current, donorsButtonMobile.current, learnMoreButtonMobile.current, '.side-bar-blocker', backSideBarButton.current]);
      
      const tl = gsap.timeline({paused:true});
      if(window.innerWidth<window.innerHeight){
        // aniamte out the side bar elements // mobile
        tl.to([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, donorsButtonMobile.current, learnMoreButton.current, learnMoreButtonMobile.current],{ autoAlpha:0, duration:1, ease:"Quad.easeIn"},0);
        let gradient = {value: 'linear-gradient(177deg,white 15vh,transparent 15.1vh)'};
        let target = sideBarBase.current;
        tl.to(gradient, {
          value: 'linear-gradient(177deg,white 110vh,transparent 110.1vh)',
          duration: 1,
          ease: "Back.easeInOut",
          onUpdate: () =>{
            // console.log(gradient.value);
            return target.style['-webkit-mask-image'] = gradient.value
          }
        },0);
        tl.set(horseLogo.current,{autoAlpha:0,x:0,display:"block"},0);
        //tl.to(horseLogo.current,{autoAlpha:1,},1);
      } else {
        // aniamte out the side bar elements // desktop
        tl.to([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current, horseLogo.current],{stagger: 0.08,autoAlpha:0, x:0, duration:.5},0);
        let gradient = {value: 'linear-gradient(92deg,white 25%,transparent 25.1%)'};
        let target = sideBarBase.current;
        tl.to(gradient, {
          value: 'linear-gradient(92deg,white 100%,transparent 100.1%)',
          duration: 1,
          ease: "Back.easeInOut",
          onUpdate: () =>{
            
            return target.style['-webkit-mask-image'] = gradient.value
          }
        },0);
        tl.set(horseLogo.current,{x:0, left:"47%"}, 1.5);
      }

      tl.to(['.side-bar-blocker',closeSideBarButton.current],{autoAlpha:1},1.5);
      tl.set(backSideBarButton.current,{autoAlpha:0},0);
      tl.to(horseLogo.current,{autoAlpha:1,duration:.5},1.5);

      tl.call(()=>{
        if(data.path=="share" || data.path=="contact" || data.path=="executive"){

        } else {
          eventBus.dispatch("showCTA", {});
        }
        window.sideBarOpen=true;

      },null,1.1)

      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1.5)
      
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("closeSideBarButtonClicked", (data) =>{

      gsap.killTweensOf(['.side-bar-blocker', sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current,donorsButtonMobile.current, learnMoreButton.current, learnMoreButtonMobile.current, horseLogo.current]);

      const tl = gsap.timeline({paused:true});
      tl.to(['.side-bar-blocker',closeSideBarButton.current],{autoAlpha:0});
      if(window.innerWidth<window.innerHeight){
        let gradient = {value: 'linear-gradient(177deg,white 110vh,transparent 110.1vh)'};
        let target = sideBarBase.current;
        tl.to(gradient, {
          value: 'linear-gradient(177deg,white 15vh,transparent 15.1vh)',
          duration: 1,
          ease: "Back.easeInOut",
          onUpdate: () =>{
            return target.style['-webkit-mask-image'] = gradient.value
          }
        });
        tl.to([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current,donorsButtonMobile.current, learnMoreButton.current, learnMoreButtonMobile.current],{ stagger: 0.08, autoAlpha:1, x:0},1);
        tl.to(horseLogo.current,{autoAlpha:0},0);
      } else {
        let gradient = {value: 'linear-gradient(92deg,white 100%,transparent 100.1%)'};
        let target = sideBarBase.current;
        tl.to(gradient, {
          value: 'linear-gradient(92deg,white 25%,transparent 25.1%)',
          duration: 1,
          ease: "Back.easeInOut",
          onUpdate: () =>{
            return target.style['-webkit-mask-image'] = gradient.value
          }
        });
        tl.set(horseLogo.current,{autoAlpha:0,duration:.5},0);
        tl.set(horseLogo.current,{x:100, left:"3vw"},.5);
        tl.to([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current, horseLogo.current],{ stagger: 0.08, autoAlpha:1, x:0},.5);
      }
      tl.call(()=>{
        eventBus.dispatch("hideCTA", {});
        window.sideBarOpen=false;
      },null,0)
      tl.play();
    });

    eventBus.on("hideHorse", (data) =>{
      gsap.killTweensOf([horseLogo.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(horseLogo.current,{autoAlpha:0, duration:.5},0);
      tl.play();
    });

    eventBus.on("showHorse", (data) =>{
      gsap.killTweensOf([horseLogo.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(horseLogo.current,{autoAlpha:1, duration:.5},.5);
      tl.play();
    });

    eventBus.on("showSideBarBackButton", (data) =>{
      gsap.killTweensOf([closeSideBarButton.current, backSideBarButton.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(closeSideBarButton.current,{autoAlpha:0, duration:.5},0);
      tl.to(backSideBarButton.current,{autoAlpha:1,duration:.5},0);
      tl.play();
    });

    eventBus.on("hideSideBarBackButton", (data) =>{
      gsap.killTweensOf([backSideBarButton.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(backSideBarButton.current,{autoAlpha:0,duration:.5},0);
      tl.play();
    });

    eventBus.on("showSideBarCloseButton", (data) =>{
      gsap.killTweensOf([closeSideBarButton.current, backSideBarButton.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(closeSideBarButton.current,{autoAlpha:1,duration:.5},0);
      tl.to(backSideBarButton.current,{autoAlpha:0,duration:.5},0);
      tl.play();
    });

    eventBus.on("showCloseButtonMobile", (data) =>{
      gsap.killTweensOf([closeButtonMobile.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(closeButtonMobile.current,{autoAlpha:1,duration:.5},0);
      tl.play();
      eventBus.dispatch("hideSideBarButtons",{});
    });

    eventBus.on("hideCloseButtonMobile", (data) =>{
      gsap.killTweensOf([closeButtonMobile.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(closeButtonMobile.current,{autoAlpha:0,duration:.5},0);
      tl.play();
      eventBus.dispatch("showSideBarButtons",{});
    });

    eventBus.on("hideSideBarButtons", (data) =>{
      gsap.killTweensOf([learnMoreButtonMobile.current, donorsButtonMobile.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(learnMoreButtonMobile.current,{autoAlpha:0,duration:.5},0);
      tl.to(donorsButtonMobile.current,{autoAlpha:0,duration:.5},0);
      tl.play();
    });

    eventBus.on("showSideBarButtons", (data) =>{
      gsap.killTweensOf([learnMoreButtonMobile.current, donorsButtonMobile.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(learnMoreButtonMobile.current,{autoAlpha:1,duration:.5},0);
      tl.to(donorsButtonMobile.current,{autoAlpha:1,duration:.5},0);
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("showMobileContent", (data) =>{
      gsap.killTweensOf([mobileContentEl.current]);

      eventBus.dispatch("disableNormalSideBarText",{});

      const tl = gsap.timeline({paused:true});
      tl.to(mobileContentEl.current,{autoAlpha:1,duration:.5},0);
      tl.play();
      if(window.innerWidth<window.innerHeight){
        eventBus.dispatch("hide360Image",{});
      }
    });

    eventBus.on("hideMobileContent", (data) =>{
      gsap.killTweensOf([mobileContentEl.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(mobileContentEl.current,{autoAlpha:0,duration:.5},0);
      tl.play();
      if(window.innerWidth<window.innerHeight){
        eventBus.dispatch("show360Image",{});
      }
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("disableNormalSideBarText", (data) =>{
      const tl = gsap.timeline({paused:true});
      tl.call(()=>{
        sideBarTextEl.current.classList.add('mobile-active')
      },null,0)
      tl.play();
    });

    eventBus.on("restoreNormalSideBarText", (data) =>{
      const tl = gsap.timeline({paused:true});
      tl.call(()=>{
        sideBarTextEl.current.classList.remove('mobile-active')
      },null,.5)
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("updateSideBarContent", (data)=>{
      if(!data.path) { return; }
      if(data.path!="donors" && data.path!="gallery" && data.path!="dean" && data.path!="donor" && data.path!="key"  && data.path!="discover" && data.path!="success"  ) {window.returnPath = data.path; }
      // console.log("updateSideBarContent / cur = " + data.path, "prev = " + window.returnPath);
      
      gsap.killTweensOf([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current]);
      
      const contentDelay  = .1;
      const animDelay     = .5;
      const animTime      = .25;

      const tl = gsap.timeline({paused:true});
      tl.to([sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{stagger: 0, autoAlpha:0, x:0, duration:contentDelay}, 0);

      switch(data.path){
        case "landing":
          tl.call(()=>{
            setSideBarTitle(_landingTitle);
            setSideBarText(_landingText);
          },null,contentDelay);
          tl.to([ sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "donors":
          tl.call(()=>{
            setSideBarTitle(_donorsTitle);
            setSideBarText(_donorsText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "rotunda":
          tl.call(()=>{
            setSideBarTitle(_rotundaTitle);
            setSideBarText(_rotundaText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "gallery":
          tl.call(()=>{
            setSideBarTitle(_galleryTitle);
            setSideBarText(_galleryText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "dean":
          tl.call(()=>{
            setSideBarTitle(_deanTitle);
            setSideBarText(_deanText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "donor":
          tl.call(()=>{
            setSideBarTitle(_donorTitle);
            setSideBarText(_donorText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "room1":
          tl.call(()=>{
            setSideBarTitle(_room1Title);
            setSideBarText(_room1Text);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "room2":
          tl.call(()=>{
            setSideBarTitle(_room2Title);
            setSideBarText(_room2Text);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "room3":
          tl.call(()=>{
            setSideBarTitle(_room3Title);
            setSideBarText(_room3Text);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current, donorsButton.current, learnMoreButton.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "success":
          tl.call(()=>{
            switch(data.room){
              case 1:
                setSideBarTitle(_success1Title);
                setSideBarText(_success1Text);
                break;
              case 2:
                setSideBarTitle(_success2Title);
                setSideBarText(_success2Text);
                break;
              case 3:
                setSideBarTitle(_success3Title);
                setSideBarText(_success3Text);
                break;
              default:
                break;
            }
            
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "key":
          tl.call(()=>{
            setSideBarTitle(_keyTitle);
            setSideBarText(_keyText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        case "discover":
          tl.call(()=>{
            setSideBarTitle(_discoverTitle);
            setSideBarText(_discoverText);
          },null,contentDelay);
          tl.to([logo.current, sideBarTitleEl.current,sideBarTextEl.current],{ stagger: 0.08, autoAlpha:1, x:0, duration:animTime},(data.delay)?data.delay:animDelay);
          break;
        default:
          break;
      }
      tl.play();
    })

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideDonors", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideGallery", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideDean", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideDonor", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideKey", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideSuccess", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

    eventBus.on("hideDiscover", (data)=>{
      eventBus.dispatch("updateSideBarContent",{path: window.returnPath});
    });

  },[])

  /////////////////////////////////////////////////////////////////////////////////
  
  const sidebarMobileContentlicked = () =>{
    eventBus.dispatch("hideMobileContent",{});
  }

  const logoClicked = (e) =>{
    // eventBus.dispatch("logolicked", {});
    // eventBus.dispatch("hideRotunda");
    // eventBus.dispatch("updateSideBarContent",{path:"landing",delay:3})
    // eventBus.dispatch("showLanding");

    // eventBus.dispatch("hideDonors");

    // if( window.sideBarOpen ){
    //   eventBus.dispatch("closeSideBarButtonClicked");
    //   eventBus.dispatch("hideSideBarBackButton",{});
    //   eventBus.dispatch("hideShare");
    //   eventBus.dispatch("hideContact");
    //   eventBus.dispatch("hideExecutive");
    // }

    // eventBus.dispatch("hideDonor");
    // eventBus.dispatch("hideDean");

    // eventBus.dispatch("hideKey");
    // eventBus.dispatch("hideSuccess");
    // eventBus.dispatch("hideDiscover");
  }

  const learnMoreButtonClicked = (e) =>{
    //alert("Learn More Button clicked")
    eventBus.dispatch("learnMoreButtonClicked", {});

    /////////////////////////////////////////////////////////////////////////////////

      window.history.pushState({},'','./learnmore');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': 'learnMore',
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / learnMore");
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

  }

  const donorsButtonClicked = (e) =>{
    //alert("Donors Button clicked")
    eventBus.dispatch("showDonors", {});
    eventBus.dispatch("updateSideBarContent",{path:"donors"});
  }

  const closeSideBarButtonClicked = (e) =>{
    //alert("close side bar")
    eventBus.dispatch("closeSideBarButtonClicked", {});
  }

  const closeButtonMobileClicked = (e) =>{
    //alert("close side bar")
    eventBus.dispatch("closeButtonMobileClicked", {});
  }

  const backSideBarButtonClicked = (e) =>{
    eventBus.dispatch("backSideBarButtonClicked", {});
    eventBus.dispatch("hideShare");
    eventBus.dispatch("hideContact");
    eventBus.dispatch("hideExecutive");
    eventBus.dispatch("showSideBarCloseButton",{delay:0});
    eventBus.dispatch("showCTA",{delay:0});
  }

  /////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="side-bar" ref={sideBar}>
      <div className='side-bar-blocker' ></div>
      <img ref={sideBarBase } className="side-bar-base" src={SideBarBase} alt="side bar background" aria-hidden={true} />
      <img ref={logo} className="logo" src={Logo} onClick={logoClicked}  alt="SMU Logo"/>
      <div className="sidebar-content">
        <div className="sidebar-title" ref={sideBarTitleEl} >{sideBarTitle}</div>
        <div className="sidebar-text" ref={sideBarTextEl} >{sideBarText}</div>
      </div>
      <div className="sidebar-content-mobile" ref={mobileContentEl} onClick={sidebarMobileContentlicked}>
        <img ref={watercolorBackgroundRef} className="sidebar-watercolor-mobile" src={watercolorBackground} alt="" />
        <img src={mobileModalBack} alt="" className="sidebar-content-mobile-back" />
        <div className="sidebar-text-mobile">{sideBarText}</div>
        <img src={modalLogo} alt="" className="modal-logo" />
        <img src={modalExploreButton} alt="" className="modal-explore-button" />
      </div>
      <img ref={donorsButton} onClick={donorsButtonClicked} className="donors-button" src={DonorsButton} />
      <img ref={learnMoreButton} onClick={learnMoreButtonClicked} className="learn-more-button" src={LearnMoreButton} />
      <img ref={donorsButtonMobile} onClick={donorsButtonClicked} className="donors-button-mobile" src={DonorsButtonMobile} />
      <img ref={learnMoreButtonMobile} onClick={learnMoreButtonClicked} className="learn-more-button-mobile" src={LearnMoreButtonMobile} />
      <img ref={horseLogo} className="side-bar-horse" src={HorseLogo} />
      <img ref={closeSideBarButton}  onClick={closeSideBarButtonClicked} className="side-bar-close-button" src={SideBarCloseButton} />
      <img ref={backSideBarButton} onClick={backSideBarButtonClicked} className="side-bar-back-button" src={SideBarBackButton} />
      <img ref={closeButtonMobile}  onClick={closeButtonMobileClicked} className="side-bar-close-button-mobile" src={SideBarCloseButton} />
    </div>
    
  );
}

export default SideBar;
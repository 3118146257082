import './Donors.css';
import DonorsCloseButton from '../../assets/images/close-button.svg';
import watercolorBackground from '../../assets/images/watercolor.png';
import donorBack from '../../assets/images/donor-back.png';
import donorBackm from '../../assets/images/donor-mobile-back.png';
import donor1 from '../../assets/images/donor-1.png';
import donor2 from '../../assets/images/donor-2.png';
import donor3 from '../../assets/images/donor-3.png';
import donor4 from '../../assets/images/donor-4.png';
import donor5 from '../../assets/images/donor-5.png';
import donor6 from '../../assets/images/donor-6.png';
import donor7 from '../../assets/images/donor-7.png';
import donor8 from '../../assets/images/donor-8.png';
import donor9 from '../../assets/images/donor-9.png';
import donor1m from '../../assets/images/donor-1-m.png';
import donor2m from '../../assets/images/donor-2-m.png';
import donor3m from '../../assets/images/donor-3-m.png';
import donor4m from '../../assets/images/donor-4-m.png';
import donor5m from '../../assets/images/donor-5-m.png';
import donor6m from '../../assets/images/donor-6-m.png';
import donor7m from '../../assets/images/donor-7-m.png';
import donor8m from '../../assets/images/donor-8-m.png';
import donor9m from '../../assets/images/donor-9-m.png';
import LeftArrow from '../../assets/images/left-arrow.png';
import RightArrow from '../../assets/images/right-arrow.png';
import React, { useEffect, useRef, useState } from 'react';
import eventBus from '../EventBus';
import gsap from 'gsap';

function Donors() {
  const donors = useRef();
  const closeDonorsButton = useRef();
  const rightArrow = useRef();
  const leftArrow = useRef();
  const watercolorBackgroundRef = useRef();

  const [cardIndex, setCardIndex] = useState(0);

  const cards = [
    {
      src: donor1,
      srcm: donor1m,
      title: (
        <>
          Carolyn and
          <br />
          David B. Miller
        </>
      ),
      text: (
        <>
          <p>
            “This gift is an investment in the next generation of entrepreneurs and big ideas. I am excited to support
            Cox’s forward-focused vision for advancing business education and developing talented business leaders, and
            can’t wait to see what Cox’s future holds.”
          </p>
          <p>
            <b>David Miller</b> ’72, ’73
            <br />
            <i>Co-founder and Managing Partner of EnCap Investments LP</i>
          </p>
        </>
      ),
    },
    {
      src: donor2,
      srcm: donor2m,
      title: (
        <>
          Sharoll and
          <br />
          Bryan S. Sheffield
        </>
      ),
      text: (
        <>
          <p>
            “The Cox School taught me to anticipate future business landscapes. This facility will give our
            next-generation CEOs experience in a data-driven, collaborative environment – and the skills to build trust
            with customers. Sharoll and I are thrilled to give back to SMU and to educate the next generation of
            entrepreneurs.”
          </p>
          <p>
            <b>Bryan S. Sheffield</b> ’01
            <br />
            <i>Founder and Managing Partner of Formentera Partners</i>
          </p>
        </>
      ),
    },
    {
      src: donor3,
      srcm: donor3m,
      title: (
        <>
          Gina L. and
          <br />
          Tucker S. Bridwell
        </>
      ),
      text: (
        <>
          <p>
            “SMU has truly made the American dream possible for me. SMU is a place that encourages students to think and
            lead. Gina and I are excited to support Cox and see the future impact of the Bridwell Institute. We can’t
            wait to see how future Mustangs, using their SMU education, change the business landscape.”
          </p>
          <p>
            <b>Tucker S. Bridwell</b> ’73, ’74
            <br />
            <i>President of the Mansefeldt Investment Corporation and the Dian Graves Owen Foundation</i>
          </p>
        </>
      ),
    },
    {
      src: donor4,
      srcm: donor4m,
      title: (
        <>
          Aurelia and
          <br />
          Brad K. Heppner
        </>
      ),
      text: (
        <>
          <p>
            “The SMU Cox School of Business is a vital part of Dallas’ thriving business community. We are proud to
            invest in the next generation of business leaders who are building their skills in the Cox School, and in
            faculty who conduct ambitious research.”
          </p>
          <p>
            <b>Brad K. Heppner</b> ’88
            <br />
            <i>Founder, Chairman and CEO of The Beneficient Company Group LP</i>
          </p>
        </>
      ),
    },
    {
      src: donor5,
      srcm: donor5m,
      title: (
        <>
          Jane and
          <br />
          Pat S. Bolin
        </>
      ),
      text: (
        <>
          <p>
            “Jane and I were inspired by the generous gift to SMU made by Gina and Tucker Bridwell, who have been great
            friends to us for more than 50 years. We feel this is the perfect time to invest in the future of the Cox
            School and in preparing students for the ever-evolving world of business.”
          </p>
          <p>
            <b>Pat S. Bolin</b> ’73
            <br />
            <i>Executive Chairman of Eagle Oil & Gas Co.</i>
          </p>
        </>
      ),
    },
    {
      src: donor6,
      srcm: donor6m,
      title: (
        <>
          Kimberley and
          <br />
          William C. Shaddock
        </>
      ),
      text: (
        <>
          <p>
            “While I have been involved in building entrepreneurship programs outside of SMU, my gift to SMU is a
            tangible expression of my appreciation to the University for what it has meant to my family, my business
            success and myself personally. I am delighted to be a part of this great university experience for the next
            young entrepreneur.”
          </p>
          <p>
            <b>William C. Shaddock</b> ’74
            <br />
            <i>Owner of The Shaddock Companies</i>
          </p>
        </>
      ),
    },
    {
      src: donor7,
      srcm: donor7m,
      title: (
        <>
          Katy and
          <br />
          Kyle D. Miller
        </>
      ),
      text: (
        <>
          <p>
            “The opportunity to have a focused energy management program at the Cox School where we’re situated in the
            Southwest and the center of the domestic energy industry, makes all the sense in the world. I was thrilled
            to support this important endeavor.”
          </p>
          <p>
            <b>Kyle D. Miller</b> ’01
            <br />
            <i>Energy Leader</i>
          </p>
        </>
      ),
    },
    {
      src: donor8,
      srcm: donor8m,
      title: (
        <>
          Mary Lee and
          <br />
          Fritz Duda
        </>
      ),
      text: (
        <>
          <p>
            “The Cox School education opens doors and provides a wealth of professional opportunities. The Duda Family
            takes pride in supporting these facilities that inspire and empower tomorrow’s leaders and in honoring our
            beloved son, Michael Christopher Duda ’11, who was a proud Cox School alumnus.”
          </p>
          <p>
            <b>Fritz Duda</b>
            <br />
            <i>The Fritz and Mary Lee Duda Foundation</i>
          </p>
        </>
      ),
    },
    {
      src: donor9,
      srcm: donor9m,
      title: (
        <>
          Sylvie P. and
          <br />
          Gary T. Crum
        </>
      ),
      text: (
        <>
          <p>
            “The education that I received at the Cox School and the connections I made as a student helped shape me
            both as a businessman and as a person, and Sylvie and I are honored to be a part of shaping the next
            generation of business leaders in our industry and philanthropists for our community.”
          </p>
          <p>
            <b>Gary T. Crum</b> ’69
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    showNextCard();
  }, [cardIndex]);

  useEffect(() => {
    eventBus.on('showDonors', (data) => {
      // console.log("show donors")
      setCardIndex(0);

      /////////////////////////////////////////////////////////////////////////////////

      window.view = 'donors';

      // window.history.pushState({},'','./landing');

      setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'virtualPageView',
          pageURL: window.view,
          username: window.username ? window.username : 'publicVisitor',
        });

        console.log('virtualPageView / ' + window.view);
      }, 100);

      /////////////////////////////////////////////////////////////////////////////////

      eventBus.dispatch('enableClickBlocker');
      eventBus.dispatch('hideTopControls');

      gsap.killTweensOf([
        '.donors-cards',
        leftArrow.current,
        rightArrow.current,
        '.donors-nav',
        donors.current,
        closeDonorsButton.current,
      ]);
      const tl = gsap.timeline({ paused: true });
      if (window.innerHeight > window.innerWidth) {
        tl.set(['.donors-cards', leftArrow.current, rightArrow.current, '.donors-nav'], { autoAlpha: 0 });
      }
      tl.to([donors.current], { autoAlpha: 1, duration: 1 }, 0.3);
      tl.to([closeDonorsButton.current], { autoAlpha: 1, duration: 0.5 }, 0.3);
      tl.call(
        () => {
          eventBus.dispatch('disableClickBlocker');
        },
        null,
        1
      );
      tl.play();
      showNextCard();
      eventBus.dispatch('hideLandingEnter', {});
      eventBus.dispatch('showCloseButtonMobile', {});
      eventBus.dispatch('showMobileContent', {});
    });

    eventBus.on('hideDonors', (data) => {
      // console.log("hide donors");

      gsap.killTweensOf([
        '.donors-cards',
        leftArrow.current,
        rightArrow.current,
        '.donors-nav',
        donors.current,
        closeDonorsButton.current,
      ]);
      const tl = gsap.timeline({ paused: true });
      tl.to([donors.current], { autoAlpha: 0, duration: 0.5 }, 0);
      tl.to([closeDonorsButton.current], { autoAlpha: 0, duration: 0.5 }, 0);
      tl.call(
        () => {
          eventBus.dispatch('hideMobileContent', {});
        },
        null,
        0
      );
      tl.call(
        () => {
          // JAY console.log("window.view = " + window.view, "window.returnPath = " + window.returnPath);
          eventBus.dispatch('showLandingEnter', {});
          eventBus.dispatch('showTopControls', {
            showBack: window.returnPath == 'landing' ? false : true,
            showMute: true,
            showShare: true,
          });
        },
        null,
        1
      );
      tl.play();

      eventBus.dispatch('hideCloseButtonMobile', {});

      //

      // JAY console.log("window.view = " + window.view, "window.returnPath = " + window.returnPath);
      if (window.returnPath == 'landing') {
        eventBus.dispatch('restoreNormalSideBarText', {});
      } else {
        //
      }
    });

    eventBus.on('closeButtonMobileClicked', (data) => {
      if (window.view === 'donors') {
        eventBus.dispatch('hideDonors');
      }
    });

    eventBus.on('hideMobileContent', (data) => {
      gsap.killTweensOf(['.donors-cards']);

      const tl = gsap.timeline({ paused: true });
      tl.to(
        ['.donors-cards', leftArrow.current, rightArrow.current, '.donors-nav'],
        { autoAlpha: 1, duration: 1 },
        0.5
      );
      tl.play();
    });
  }, []);

  const showNextCard = () => {
    gsap.killTweensOf(['.donors-card', '.donors-card-' + cardIndex]);

    const tl = gsap.timeline({ paused: true });
    tl.to('.donors-card', { autoAlpha: 0 }, 0);
    tl.to('.donors-card-' + cardIndex, { autoAlpha: 1 });
    tl.play();

    // update the bottom nav
  };

  const closeDonorsButtonClicked = () => {
    eventBus.dispatch('hideDonors');
  };

  const rightClicked = () => {
    setCardIndex((cardIndex + cards.length + 1) % cards.length);
  };

  const leftClicked = () => {
    setCardIndex((cardIndex + cards.length - 1) % cards.length);
  };

  return (
    <div className={'donors'} ref={donors}>
      <div className="donors-background"></div>
      <div className="donors-cards">
        {cards.map((c, i) => (
          <div key={i} className={`donors-card donors-card-${i}`}>
            <div className="donors-card-title">{c.title}</div>
            <div className="donors-card-text">{c.text}</div>
            <img src={c.src} className="donors-card-image" alt="" />
            <img src={c.srcm} className="donors-card-image-mobile" alt="" />
            <img src={donorBack} className="donors-card-back" alt="" />
            <img src={donorBackm} className="donors-card-back-mobile" alt="" />
          </div>
        ))}
      </div>
      <img ref={watercolorBackgroundRef} className="donors-watercolor-background" src={watercolorBackground} alt="" />
      <img onClick={leftClicked} className="donors-left-arrow" ref={leftArrow} src={LeftArrow} alt="" />
      <img onClick={rightClicked} className="donors-right-arrow" ref={rightArrow} src={RightArrow} alt="" />
      <img
        ref={closeDonorsButton}
        onClick={closeDonorsButtonClicked}
        className="donors-close-button"
        src={DonorsCloseButton}
      />
      <div className="donors-nav">
        <div className="donors-nav-numbers">
          {cardIndex + 1} of {cards.length}
        </div>
        <div className="donors-nav-text">&lt;&lt; CLICK TO EXPLORE &gt;&gt;</div>
      </div>
    </div>
  );
}

export default Donors;

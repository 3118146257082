import './Contact.css';
import submit from '../../assets/images/submit.png';
import continueButtonImage from '../../assets/images/continue-button.png';
import personIcon from '../../assets/images/person-icon.svg';
import emailIcon from '../../assets/images/email-icon.svg';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';
import Confetti from 'react-confetti'
import { useWindowSize} from '@react-hook/window-size'
 

function Contact({roomName, room}) {

  const [width, height] = useWindowSize()

  const contact = useRef();
  const classRoomsButton = useRef();
  const teamRoomsButton  =useRef();
  const nameInput = useRef();
  const emailInput = useRef();
  const messageInput = useRef();

  const [showThanks, setShowThanks] = useState(false);


  useEffect(() => {
    eventBus.on("showContact", (data) =>{
      // console.log("show conntact")
      
      /////////////////////////////////////////////////////////////////////////////////

      window.view = 'contact';

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);

      },100)

      /////////////////////////////////////////////////////////////////////////////////


      eventBus.dispatch("enableClickBlocker");
      
      const tl = gsap.timeline({paused:true});
      tl.to([contact.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
    });

    eventBus.on("hideContact", data =>{
      // console.log("hide contact");
      const tl = gsap.timeline({paused:true});
      tl.to([contact.current],{ autoAlpha:0, duration:.5},0);
      tl.play();
  
    })

    
    // FIX For iOS Virtual keboard bug
    nameInput.current.onfocus = (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

    nameInput.current.onblur = (e) => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

    emailInput.current.onfocus = (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

    emailInput.current.onblur = (e) => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

    messageInput.current.onfocus = (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      contact.current.scrollTop = 0;
    };

    messageInput.current.onblur = (e) => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

  }, [])



  const submitClicked =(e)=>{
    e.preventDefault();
    //validet form, if errors return false;
    const data = {
      name: nameInput.current.value,
      email: emailInput.current.value,
      team: teamRoomsButton.current.classList.contains('active'),
      class: classRoomsButton.current.classList.contains('active'),
      message: messageInput.current.value,
    }

    fetch("/api/email", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data) 
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.error) {
        alert(data.error);
        return;
      }
      setShowThanks(true);
    })


   
    return false;
  }

  const classRoomsButtonClicked = () =>{
    if(classRoomsButton.current.classList.contains('active')){
      classRoomsButton.current.classList.remove('active');
    } else {
      classRoomsButton.current.classList.add('active');
    }
  }

  const teamRoomsButtonClicked = () =>{
    if(teamRoomsButton.current.classList.contains('active')){
      teamRoomsButton.current.classList.remove('active');
    } else {
      teamRoomsButton.current.classList.add('active');
    }
  }

  const continueClicked = () =>{
    eventBus.dispatch("backSideBarButtonClicked", {});
    eventBus.dispatch("hideShare");
    eventBus.dispatch("hideContact");
    eventBus.dispatch("hideExecutive");
    eventBus.dispatch("showSideBarCloseButton",{delay:0});
    eventBus.dispatch("showCTA",{delay:.5});
  }
  
  return (
      <div className={'contact'} ref={contact} >
        { !showThanks &&
        <>
        <h1 className="title">Contact Us</h1>
        <div className="divider"></div>
        <form onSubmit={submitClicked} >
          <div className="input-wrapper">
            <img src={personIcon} alt="" />
            <input required={true} ref={nameInput} type="text" className="name" id="name" placeholder="Name*" />
          </div>
          <div className="input-wrapper">
            <img src={emailIcon} alt="" />
            <input type={"email"} required={true}  ref={emailInput} className="email" id="email" placeholder="Email Address*" />
          </div>
          
          
          <p>I'm interested in:</p>
          <div className="interested-buttons">
            <button onClick={classRoomsButtonClicked} type="button" ref={classRoomsButton}  className={`classrooms-button`}>Classrooms</button>
            <button onClick={teamRoomsButtonClicked} type="button"  ref={teamRoomsButton}  className={`teamrooms-button`}>Team Rooms</button>
          </div>
          <textarea ref={messageInput} className="message" placeholder="Message">
          </textarea>
          <button  className="submit-button"><img src={submit} alt="submit" /></button>
        </form>
        </>
        }
        {showThanks && 
        <>
        <h1 className="title">Thank You!</h1>
        <div className="divider"></div>
        
          <p>Thank you for your interest in the shaping the next generation of leaders at Cox School of Business. We are grateful to you for taking the time to learn more about what Cox’s future holds. We are excited about the second century of Cox School and would be happy to share more details about our vision. </p>
          <p>If you would like more information, please reach out to Ashley Pitts at <a href="mailto:apitts@smu.edu"  target='_blank'>apitts@smu.edu</a>.</p>
          <button  onClick={continueClicked} className="continue-button"><img src={continueButtonImage} alt="submit" /></button>
          <Confetti
            className="thanks-confetti"
            width={width}
            height={height}
            // gravity={.01}
            numberOfPieces={50}
            colors={['#0f5fbe','#d70c4c' ]}
          />
        </>
        
        }
      </div>
  );
}

export default Contact;
import './Blocker.css';
import React,{ useEffect, useRef} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

function Blocker() {

  const blocker = useRef();
  
  useEffect(() => {

    eventBus.on("enableClickBlocker", (data)=>{
      gsap.killTweensOf(['click-blocker']);
      const tl = gsap.timeline({paused:true});
      tl.to(['.click-blocker'],{autoAlpha:1},0);
      tl.play();
    });

    eventBus.on("disableClickBlocker", (data)=>{
        gsap.killTweensOf(['click-blocker']);
        const tl = gsap.timeline({paused:true});
        tl.to(['.click-blocker'],{autoAlpha:0},0);
        tl.play();
    });
    
  }, [])

  return (
      <div className={'click-blocker'} ref={blocker} />
  );
}

export default Blocker;
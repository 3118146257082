import './Video.css';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import ReactPlayer from 'react-player'
import gsap from 'gsap';

function Video() {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const video = useRef();
  const videoRef = useRef();

  // console.log("render video")
  useEffect(() => {
    eventBus.on("showVideo", (data) =>{
      
      // if(!data){data={};}
      // console.log("showVideo");

      /////////////////////////////////////////////////////////////////////////////////

      // window.history.pushState({},'','./intro');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': 'intro',
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / intro");
        // console.log("virtualPageView / window.username = " + window.username);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      gsap.killTweensOf([video.current]);

      eventBus.dispatch("enableClickBlocker");

      setPlaying(true);
      const tl = gsap.timeline({paused:true});
      tl.to(video.current,{autoAlpha:1,duration:.5},(data.delay)?data.delay:.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();

      //

      eventBus.dispatch("showTopControls",{showShare:false, showBack:false, showSkip:true, showMute:true})

    });

    eventBus.on("hideVideo", (data) =>{
      // console.log("hide video")

      gsap.killTweensOf([video.current]);

      setPlaying(false);
      const tl = gsap.timeline({paused:true});
      tl.to(video.current,{autoAlpha:0,duration:.5},(data.delay)?data.delay:0);
      tl.play();
      eventBus.dispatch("showLanding", {delay:0});
    });

    eventBus.on("muteVideo", (data) =>{
      setMuted(prevMuted => !prevMuted);
    })

  }, [])

  
  const videoEnded = (e) =>{
    eventBus.dispatch("hideVideo", {});
  }

  /////////////////////////////////////////////////////////////////////////////////

  return (
      <div ref={video} className="video">
        <div className='player-wrapper'>
          <ReactPlayer
            ref={videoRef}
            muted = {muted}
            volume={.3}
            playsinline ={true}
            playing ={playing}
            width={"100%"}
            height={"100%"}
            className="videoPlayer" 
            url='https://vimeo.com/591971775/b9974c5446' 
            onEnded = {videoEnded}
          />
        </div>
      </div>
     
  );
}

export default Video;
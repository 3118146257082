import './Donor.css';
import DonorCloseButton from '../../assets/images/close-button.svg';
import watercolorBackground from '../../assets/images/watercolor.png';
import donorBack from '../../assets/images/lead-donor-background.png';
import donorBackm from '../../assets/images/dean-background-m.png';
import donor1 from '../../assets/images/lead-donor.png';
import donor1m from '../../assets/images/lead-donor-m.png';
import LeftArrow from '../../assets/images/left-arrow.png';
import RightArrow from '../../assets/images/right-arrow.png';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

function Donor() {

  const donor = useRef();
  const closeDonorButton = useRef();
  const rightArrow = useRef();
  const leftArrow = useRef();
  const watercolorBackgroundRef = useRef();

  const [cardIndex,setCardIndex] = useState(0);

  const cards = [
    {
      src: donor1,
      srcm: donor1m,
      title:<>David B. Miller<br />
      <i>SMU Trustee and Cox Executive Board Chair</i></>,
      text: <><p>“SMU has been tremendously important in my own life. I remember walking across the stage to get my diploma, and I had this hope I’d be able to give something back to this University. My wife and I were inspired to make the gift to Cox because we saw the University continue to move forward. Cox is absolutely producing future leaders that are going to go out into the world and have a significant impact.”</p>
      <p><b>David Miller</b> ’72, ’73<br />
      <i>Co-founder and Managing Partner of EnCap Investments LP</i></p></>,
    },
  ]
   
  
  useEffect(()=>{
    showNextCard();
  },[cardIndex])
  
  useEffect(() => {
    eventBus.on("showDonor", (data) =>{
      // console.log("show donor")

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "trustee";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      setCardIndex(0);
      // gsap.to("donor", {autoAlpha:0, duration:0});


      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");
      const tl = gsap.timeline({paused:true});
      // if(window.innerHeight>window.innerWidth){
      //   tl.set(['.donor-cards',leftArrow.current, rightArrow.current, '.donor-nav'],{autoAlpha:0});
      // }
      tl.to([donor.current],{ autoAlpha:1, duration:1},.3);
      tl.to([ closeDonorButton.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      showNextCard();
      // eventBus.dispatch("hideLandingEnter",{});
      eventBus.dispatch("showCloseButtonMobile",{});
      // eventBus.dispatch("showMobileContent",{});
    });

    eventBus.on("hideDonor", data =>{
      // console.log("hide donor");
      const tl = gsap.timeline({paused:true});
      tl.to([donor.current],{ autoAlpha:0, duration:.5},0);
      tl.to([ closeDonorButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("hideMobileContent",{});
      },null,0)
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      tl.play();

      //

      eventBus.dispatch("hideCloseButtonMobile",{});
    })

    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="donor"){
        eventBus.dispatch("hideDonor");
      }
    });

    eventBus.on("hideMobileContent", data =>{
      const tl = gsap.timeline({paused:true});
      tl.to(['.donor-cards',leftArrow.current, rightArrow.current, '.donor-nav'],{ autoAlpha:1, duration:1},.5);
      tl.play();
    });
    


  }, [])

  const showNextCard = ()=>{
    const tl = gsap.timeline({paused:true});
    tl.to('.donor-card',{autoAlpha:0},0);
    tl.to('.donor-card-'+ cardIndex, {autoAlpha:1});
    tl.play();

    // update the bottom nav


  }



  const closeDonorButtonClicked = () =>{
    eventBus.dispatch("hideDonor");
    
  }

  const rightClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length+1) %  cards.length);  
    
  }

  const leftClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length-1) %  cards.length);  
    
  }

  
  return (
      <div className={'donor'} ref={donor}>
        <div className="donor-background"></div>
        <div className ="donor-cards">
            {cards.map((c,i)=>(
                <div key={i} className={`donor-card donor-card-${i}`}>
                  <div className="donor-card-title">{c.title}</div>
                  <div className="donor-card-text">{c.text}</div>
                  <img src={c.src} className="donor-card-image" alt="" />
                  <img src={c.srcm} className="donor-card-image-mobile" alt="" />
                  <img src={donorBack} className="donor-card-back" alt="" />
                  <img src={donorBackm} className="donor-card-back-mobile" alt="" />
                </div>
            )
        )}
        </div>
        <img ref={watercolorBackgroundRef} className="donor-watercolor-background" src={watercolorBackground} alt="" />
        <img onClick={leftClicked} className="donor-left-arrow" ref={leftArrow} src={LeftArrow} alt="" />
        <img onClick={rightClicked} className="donor-right-arrow" ref={rightArrow} src={RightArrow} alt="" />
         <img ref={closeDonorButton}  onClick={closeDonorButtonClicked} className="donor-close-button" src={DonorCloseButton} />
         <div className="donor-nav">
            <div className="donor-nav-numbers">
            {cardIndex+1} of {cards.length}
            </div>
            <div className="donor-nav-text">
            &lt;&lt; CLICK TO EXPLORE &gt;&gt;
            </div>
         </div>
      </div>
  );
}

export default Donor;
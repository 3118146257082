// import icon from '../../assets/images/share.png';
import React,{ useEffect, useRef} from 'react';
// import eventBus from "../EventBus";
import logo from '../../assets/images/logo.png'
import copy from '../../assets/images/clipboard.svg'
import SideBarBase from '../../assets/images/side-bar-base.jpg';
import './AdminTools.css';

function AdminTools() {

  let url = "https://coxrenovation.smu.edu/";

  if(window.location.hostname.match('localhost')) {
    url = "http://localhost:3000/";
  }

  if(window.location.hostname.match('staging')) {
    url = "https://smu-cox-staging.herokuapp.com/";
  }

  const analyticsUrl    = 'https://marketingplatform.google.com/home';
  // const analyticsUrl = 'https://analytics.google.com/analytics/web/provision/#/provision';

  // const [data, setData] = useState(null);

  const username = useRef();
  const linkTextRef = useRef();
  const userRoom1 = useRef();
  const userRoom2 = useRef();
  const userRoom3 = useRef();
  const room1Setting = useRef();
  const room2Setting = useRef();
  const room3Setting = useRef();
  
  /////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // get initial settings
    fetch("/api/settings")
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        
        room1Setting.current.checked  = data.settings[0].room1;
        room2Setting.current.checked  = data.settings[0].room2;
        room3Setting.current.checked  = data.settings[0].room3;
      })
  }, []);

  /////////////////////////////////////////////////////////////////////////////////

  const copyToClipboard = () =>{
      linkTextRef.current.select();
      linkTextRef.current.setSelectionRange(0, 99999)
      document.execCommand("copy");
      alert("Copied the link to your clipboard: " + linkTextRef.current.value);
  }

  /////////////////////////////////////////////////////////////////////////////////

  const generateLink = () =>{
    let l = url + "?code=" + window.btoa(username.current.value + "_" + Number(userRoom1.current.checked) + Number(userRoom2.current.checked) + Number(userRoom3.current.checked));
    linkTextRef.current.value = l;
    saveLink();
  }

  /////////////////////////////////////////////////////////////////////////////////

  const updateSettings = () => {
    const data = {room1: room1Setting.current.checked, room2: room2Setting.current.checked, room3: room3Setting.current.checked }
   
    console.log("updateSettings");

    fetch("/api/settings", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.error) {
        console.log(data.error);
        return;
      }
    })
  }

  /////////////////////////////////////////////////////////////////////////////////

  const saveLink = () => {
    const data = { donorname: username.current.value,
                   code: window.btoa(username.current.value + "_" + Number(userRoom1.current.checked) + Number(userRoom2.current.checked) + Number(userRoom3.current.checked)),
                   room1: Number(userRoom1.current.checked),
                   room2: Number(userRoom2.current.checked),
                   room3: Number(userRoom3.current.checked)
                  };
  
    console.log("saveLink");
    // console.log(data);
    // console.log(data.donorname);
    // console.log(data.code);
    // console.log(data.room1);
    // console.log(data.room2);
    // console.log(data.room3);
    // console.log(username.current.value + "_" + Number(userRoom1.current.checked) + Number(userRoom2.current.checked) + Number(userRoom3.current.checked));

    fetch("/api/donors", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    .then((res) => res.json())
    .then((data) => {
      // console.log('saveLink SUCCESS');
      console.log(data);
      if(data.error) {
        console.log(data.error);
        return;
      }
    })
  }
 
  /////////////////////////////////////////////////////////////////////////////////
  
  return (
    <div className="admin-tools">
      <section className="side-bar">
      <img className="side-bar-base" src={SideBarBase} alt="side bar background" aria-hidden={true} />
       <img src={logo} alt="logo" className="logo" />
       <div className="side-bar-bottom">
         <a href={analyticsUrl} target='_blank' rel="noreferrer"><button  className="analytics-button" >Launch Analytics</button></a>
         <p>For support please contact<br /><a href="mailto:software@thevirtualwild.com" target='_blank' >software@thevirtualwild.com</a></p>
       </div>
      </section>
      <section className="main">
        <div className="top-bar">
          <h1>Admin Dashboard</h1>
        </div>
        <div className="main-content">
          <div className="main-content-section">
            <h2>Public Settings</h2>
            <div className="setting-options-row">
              <div className="setting-option">
                <span className="label">Cluster Classroom</span>
                <label className="switch">
                  <input onChange = {updateSettings} ref={room1Setting} id="room-1"type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="setting-option">
                <span className="label">Lecture Hall</span>
                <label className="switch">
                  <input onChange = {updateSettings} ref={room2Setting} id="room-2" type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="setting-option">
                <span className="label">Team Rooms</span>
                <label className="switch">
                  <input onChange = {updateSettings} ref={room3Setting} id="room-3" type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="main-content-section">
            <h2>Targeted Donor Settings</h2>
            <label htmlFor={"user-name"}>Donor Name</label><br />
            <input required={true} ref={username} type="text" className='user-name' id='user-name' placeholder='enter name here' />
            <div className="setting-options-row">
              <div className="setting-option">
                <span className="label">Cluster Classroom</span>
                <label className="switch">
                  <input ref={userRoom1} id="user-room-1"type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="setting-option">
                <span className="label">Lecture Hall</span>
                <label className="switch">
                  <input ref={userRoom2} id="user-room-2" type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="setting-option">
                <span className="label">Team Rooms</span>
                <label className="switch">
                  <input ref={userRoom3} id="user-room-3" type="checkbox" defaultChecked />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <button onClick={generateLink} type="button" className="generate-link-button" >Generate Link</button><br />
            <input type="text" ref={linkTextRef} className="link-text" id="link-text" />
            <button className="copy-button"><img src={copy} alt="copy to clipboard" onClick={copyToClipboard} /></button>
          </div>
        </div>
      </section>
      {/* <p>{!data ? "Loading..." : JSON.stringify(data.users[0])}</p> */}
    </div>
  );
}

export default AdminTools;
import './Share.css';
import email from '../../assets/images/email.svg';
import tw from '../../assets/images/twitter.svg';
import linkedin from '../../assets/images/linkedin.svg';
import fb from '../../assets/images/fb.svg';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

function Share({roomName, room}) {

  const share = useRef();
  
  useEffect(() => {
    eventBus.on("showShare", (data) =>{
      // console.log("show share")

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "share";

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      eventBus.dispatch("enableClickBlocker");

      const tl = gsap.timeline({paused:true});
      tl.to([share.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      
    });

    eventBus.on("hideShare", data =>{
      // console.log("hide share");
      const tl = gsap.timeline({paused:true});
      tl.to([share.current],{ autoAlpha:0, duration:.5},0);
      tl.play();
    })

  }, [])

  const shareFBButtonClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'shareFBButtonClicked',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / shareFBButtonClicked");

    },0)

    window.open(`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoxrenovation.smu.edu&t=SMU`);
  }

  const shareTwitterButtonClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'shareTwitterButtonClicked',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / shareTwitterButtonClicked");

    },0)

    window.open(`https://twitter.com/intent/tweet?text=Join me in supporting the next generation of leaders at SMU's Cox School of Business. It's an exciting opportunity to participate in shaping our communities and organizations of the future.&url=https%3A%2F%2Fcoxrenovation.smu.edu&hashtags=SMU`);
    
  }

  const shareLinkedInButtonClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'shareLinkedInButtonClicked',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / shareLinkedInButtonClicked");

    },0)

    window.open('https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fcoxrenovation.smu.edu/','_blank', "height=400,width=600")
    // window.open('https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fsmu.edu','_blank', "height=400,width=600")
  }

  const shareEmailButtonClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'shareEmailButtonClicked',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / shareEmailButtonClicked");

    },0)

    let emailTo = '';
    let emailSub = 'SMU | Sponsoring The Future';
    let emailBody = `Join me in supporting the next generation of leaders at SMU's Cox School of Business. It's an exciting opportunity to participate in shaping our communities and organizations of the future. \n\nhttps%3A%2F%2Fcoxrenovation.smu.edu`;

    window.open(window.location.href = "mailto:"+emailTo+'?&subject='+emailSub+'&body='+emailBody, '_blank');
  }

  return (
      <div className={'share'} ref={share}>
        <h1 className="title">Share This Website</h1>
        <div className="divider"></div>
        <div className="buttons">
          <button onClick={shareFBButtonClicked} className="share-button"><img src={fb} alt="facebook" /></button>
          <button onClick={shareTwitterButtonClicked} className="share-button"><img src={tw} alt="twitter" /></button>
          <button onClick={shareLinkedInButtonClicked} className="share-button"><img src={linkedin} alt="linked in" /></button>
          <button onClick={shareEmailButtonClicked} className="share-button"><img src={email} alt="email" /></button>
        </div>
        
      </div>
  );
}

export default Share;
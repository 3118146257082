import './Gallery.css';
import GalleryCloseButton from '../../assets/images/close-button.svg';
import LeftArrow from '../../assets/images/left-arrow.png';
import RightArrow from '../../assets/images/right-arrow.png';
import imageBackground from '../../assets/images/watercolor.png';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

function Gallery() {

  const images = useRef();
  const closeGalleryButton = useRef();
  const rightArrow = useRef();
  const leftArrow = useRef();

  const [cardIndex,setCardIndex] = useState(0);

  const cards = [];
  for(let i=1; i<=31; i++){
    cards.push({src:`./assets/images/gallery/${i}.jpg`})
  }

  
  useEffect(() => {

    

    eventBus.on("showGallery", (data) =>{
      // console.log("show gallery")
      setCardIndex(0);

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "gallery";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");

      gsap.killTweensOf([images.current, closeGalleryButton.current]);

      const tl = gsap.timeline({paused:true});
      if(window.innerHeight>window.innerWidth){
        tl.set(['.gallery-cards',leftArrow.current, rightArrow.current, '.gallery-nav'],{autoAlpha:0},0);
      }
      tl.to([images.current],{ autoAlpha:1, duration:1},.3);
      tl.to([ closeGalleryButton.current],{ autoAlpha:1, duration:.5},.3);
      if(window.innerHeight>window.innerWidth){
        tl.set(['.gallery-cards',leftArrow.current, rightArrow.current, '.gallery-nav'],{autoAlpha:0},1);
      }
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      showNextCard();
      // eventBus.dispatch("hideLandingEnter",{});
      eventBus.dispatch("showCloseButtonMobile",{});
      eventBus.dispatch("showMobileContent",{});
    });

    eventBus.on("hideGallery", data =>{
      // console.log("hide gallery");

      gsap.killTweensOf([images.current, closeGalleryButton.current]);

      const tl = gsap.timeline({paused:true});
      tl.to([images.current],{ autoAlpha:0, duration:.5},0);
      tl.to([ closeGalleryButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      tl.play();
      
      //
      
      eventBus.dispatch("hideCloseButtonMobile",{});
    })

    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="gallery"){
        eventBus.dispatch("hideGallery");
      }
    });

    eventBus.on("hideMobileContent", data =>{
      
      gsap.killTweensOf(['.gallery-cards',leftArrow.current, rightArrow.current, '.gallery-nav']);

      const tl = gsap.timeline({paused:true});
      tl.to(['.gallery-cards',leftArrow.current, rightArrow.current, '.gallery-nav'],{ autoAlpha:1, duration:1},.5);
      tl.play();
    });


  }, [])

  useEffect(()=>{
    showNextCard();
  },[cardIndex])

  const showNextCard = ()=>{
    gsap.killTweensOf(['.gallery-card','.gallery-card-'+ cardIndex]);

    const tl = gsap.timeline({paused:true});
    tl.to('.gallery-card',{autoAlpha:0},0);
    tl.to('.gallery-card-'+ cardIndex, {autoAlpha:1});
    tl.play();

    // update the bottom nav


  }


  const closeGalleryButtonClicked = () =>{
    eventBus.dispatch("hideGallery");
    
  }

  const rightClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length+1) %  cards.length);  
    
  }

  const leftClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length-1) %  cards.length);  
    
  }


  return (
      <div className={'gallery'} ref={images}>
        <div className="gallery-background"></div>
        <img src={imageBackground} className="gallery-watercolor-background" alt="" />
        <div className ="gallery-cards">
            {cards.map((c,i)=>(
                <div key={i} className={`gallery-card gallery-card-${i}`}>
                  <img src={c.src} className="gallery-card-image" alt="" />
                </div>
            )
        )}
        </div>
        <img onClick={leftClicked} className="gallery-left-arrow" ref={leftArrow} src={LeftArrow} alt="" />
        <img onClick={rightClicked} className="gallery-right-arrow" ref={rightArrow} src={RightArrow} alt="" />
         <img ref={closeGalleryButton}  onClick={closeGalleryButtonClicked} className="gallery-close-button" src={GalleryCloseButton} />
         <div className="gallery-nav">
            <div className="gallery-nav-numbers">
            {cardIndex+1} of {cards.length}
            </div>
            <div className="gallery-nav-text">
            &lt;&lt;  CLICK TO EXPLORE  &gt;&gt;
            </div>
         </div>
      </div>
  );
}

export default Gallery;
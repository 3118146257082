import './Executive.css';
import executiveImage1 from '../../assets/images/executive-image-1.jpg';
import executiveImage2 from '../../assets/images/executive-image-2.jpg';
import executiveContactButton from '../../assets/images/executive-contact-button.png';
import executiveEnrich from '../../assets/images/executive-enrich.png';
import HorseLogo from '../../assets/images/horse-logo-red.svg';
import gsap from 'gsap';


import React,{ useEffect, useRef} from 'react';
import eventBus from "../EventBus";

function Executive({roomName, room}) {

  const executive = useRef();
  const executiveContent = useRef();

  useEffect(() => {
    eventBus.on("showExecutive", (data) =>{
      // console.log("show exectuive")

      window.scrollTo(0, 0)

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "executive";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      eventBus.dispatch("enableClickBlocker");

      const tl = gsap.timeline({paused:true});
      tl.to([executive.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      eventBus.dispatch("hideHorse",{});
    });

    eventBus.on("hideExecutive", data =>{
      // console.log("hide executive");
      const tl = gsap.timeline({paused:true});
      tl.to([executive.current],{ autoAlpha:0, duration:.5},0);
      tl.play();
      eventBus.dispatch("showHorse",{});
    })
  }, [])

  const contactUsButtonClicked = (e) =>{
      eventBus.dispatch("hideExecutive");
      eventBus.dispatch("showContact",{delay:2});
  }


  return (
      <div className={'executive'}  ref={executive}>
        <h1 className="title">Executive Summary</h1>
        <div className="divider"></div>
        <div className="executive-container">
          <div className="executive-content" ref={executiveContent}>
            <div className="executive-content-block-1">
                <h2>Igniting the future 
                  of business education.</h2>
                <p>In 2020, SMU celebrated a century of business education at Cox School of Business by launching the next era of innovation and impact. The school has grown from a single classroom with two students to an internationally recognized training ground for the leaders, influencers and problem-solvers driving the economy on a global scale. With the vibrant North Texas business community as a partner, Cox School has made a measurable impact on education, business development, intellectual capital, our community and the world. With the ongoing mission of “influencing the way the world conducts business, one student at a time,” Cox School has helped propel the careers of more than 40,000 graduates worldwide. Today, we are one of the top business schools in the nation.</p>
                <img src={executiveImage2} className="executive-image-2" alt="" />
                <img src={executiveEnrich} className="executive-enrich" alt="" />
                <img src={executiveContactButton} className="executive-contact-button" onClick={contactUsButtonClicked} />
                <img src={HorseLogo} className="executive-horse-logo" alt="" /> 
            </div>
            <div className="executive-content-block-2">
                <img src={executiveImage1} className="executive-image-1" alt="" />
                <p>To compete at the highest level requires high-caliber faculty and students, the right facilities to foster educational excellence and collaboration. With a planned two-year renovation and expansion of its current facilities, Cox School of Business will further its goals and scope, sparking new developments and taking on new challenges to place it prominently and competitively on the world stage. Already, donors have risen to the challenge in record numbers, committing to gifts of more than $100 million to shape the leadership pipeline and create a 9-to-9 culture at Cox, where faculty and students are encouraged to spend their days on campus collaborating and tackling grand challenges.</p>
                <p>For over 100 years, business education at SMU has shaped world changers and created a community of diverse thinkers and industry leaders. With the help of supporters like you, we will continue to fuel the future of business education at Cox School and empower Mustangs’ entrepreneurial spirit for generations to come.</p>
            </div>
            
          </div>
        </div>
      </div>
  );
}

export default Executive;
import './Loader.css';
import HorseLogo from '../../assets/images/loader-horse.svg';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

function Loader() {
  
  const loader = useRef(); 
  // const circleBottom = useRef();
  // const circleTop = useRef();
  const circlePathRef = useRef();
  const loadingText = useRef();
  const horse = useRef();

  const CircleBottom = (props) =>{
    return(
      <svg version="1.1" viewBox="0 0 200 200" className={props.className}>
        <circle cx="100" cy="100" r="80" stroke="#004CB2" strokeWidth="1" fill="none" />
      </svg> 
    )
  }
  
  const CircleTop = (props) =>{
    return(
      <svg version="1.1" viewBox="0 0 200 200" className={props.className} >
        <circle ref={circlePathRef} cx="100" cy="100" r="80" stroke="#004CB2" strokeWidth="3" fill="none" />
      </svg> 
    )
  }

 
  useEffect(() => {
    
    eventBus.on("hideLoader",(data) =>{
      // console.log("hide loader here", data)
      const tl = gsap.timeline({paused:true});
      tl.to(loader.current,{autoAlpha:0,duration:2},(data.delay)?data.delay:2);
      tl.play();
    })

    const tl2 = gsap.timeline({paused:true});
    tl2.fromTo(loader.current,{autoAlpha:0},{autoAlpha:1});
    tl2.fromTo(circlePathRef.current, {
      drawSVG: "0",
    }, {
      duration: 3,
      drawSVG: "100%",
      onUpdate: function() {
        //console.log(Math.ceil(this.progress()*100));
        loadingText.current.innerHTML =  (Math.ceil(this.progress()*100)) + "%";
      }
    });
    tl2.play();
    
  }, [])

  

  return (
      <div ref={loader} className="loader">
        <div className="loader-container">
          <div className="loader-progress">
            <CircleBottom className="circle-bottom" />
            <CircleTop  className="circle-top" />
            <img className="horse" ref={horse} src={HorseLogo} alt="" />
          </div>
          <div ref={loadingText} className="loading-text"></div>
        </div>
      </div>

  );
}

export default Loader;
import './Dean.css';
import deanCloseButton from '../../assets/images/close-button.svg';
import watercolorBackground from '../../assets/images/watercolor.png';
import deanBack from '../../assets/images/dean-background.png';
import deanBackm from '../../assets/images/dean-background-m.png';
import dean1 from '../../assets/images/dean.png';
import dean1m from '../../assets/images/dean-m.png';
import LeftArrow from '../../assets/images/left-arrow.png';
import RightArrow from '../../assets/images/right-arrow.png';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';

function Dean() {

  const dean = useRef();
  const closeDeanButton = useRef();
  const rightArrow = useRef();
  const leftArrow = useRef();
  const watercolorBackgroundRef = useRef();

  const [cardIndex,setCardIndex] = useState(0);

  const cards = [
    {
      src: dean1,
      srcm: dean1m,
      title:<>Matthew B. Myers<br />
      <i>Dean of the Cox School of Business</i></>,
      text: <><p>“We are proud to welcome you to the new home of Cox School of Business with new expanded and updated facilities designed with the future in mind. In 2020, Cox School celebrated 100 years of business education at SMU. Now into our second century, Cox is a modern, inclusive learning community – a business school that provides students with a home base, a place to comfortably pursue their business studies and easily access colleagues and faculty members. Our goal is to maximize learning, expand collaborative opportunities and amplify our footprint in the heart of SMU, one of the country’s most beautiful academic campuses. By combining our improved and expanded technology, enhanced facilities and flexible workspaces with an updated curriculum, we are preparing our students with the business education they need to flourish and lead in a rapidly evolving world. We invite you to take a look around. We built for the future, and the future is now.“</p>
      <p><b>Matthew B. Myers</b><br />
      <i>Dean, Cox School of Business<br />Tolleson Chair of Business Leadership<br />David B. Miller Endowed Professor in Business</i></p></>,
    },
  ]
   

  



  
  useEffect(()=>{
    showNextCard();
  },[cardIndex])
  
  useEffect(() => {
    eventBus.on("showDean", (data) =>{
      // console.log("show dean")
      setCardIndex(0);
      
      /////////////////////////////////////////////////////////////////////////////////

      window.view = "dean";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);

      },100)

      /////////////////////////////////////////////////////////////////////////////////


      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");
      const tl = gsap.timeline({paused:true});
      // if(window.innerHeight>window.innerWidth){
      //   tl.set(['.dean-cards',leftArrow.current, rightArrow.current, '.dean-nav'],{autoAlpha:0});
      // }
      tl.to([dean.current],{ autoAlpha:1, duration:1},.3);
      tl.to([ closeDeanButton.current],{ autoAlpha:1, duration:.5},.3);
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();

      showNextCard();
      
      // eventBus.dispatch("hideLandingEnter",{});
      eventBus.dispatch("showCloseButtonMobile",{});
      // eventBus.dispatch("showMobileContent",{});
    });

    eventBus.on("hideDean", data =>{
      // console.log("hide dean");
      
      const tl = gsap.timeline({paused:true});
      tl.to([dean.current],{ autoAlpha:0, duration:.5},0);
      tl.to([ closeDeanButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("hideMobileContent",{});
      },null,0)
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      tl.play();
      
      //

      eventBus.dispatch("hideCloseButtonMobile",{});
    })

    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="dean"){
        eventBus.dispatch("hideDean");
      }
    });

    eventBus.on("hideMobileContent", data =>{
      const tl = gsap.timeline({paused:true});
      tl.to(['.dean-cards',leftArrow.current, rightArrow.current, '.dean-nav'],{ autoAlpha:1, duration:1},.5);
      tl.play();
    });
    


  }, [])

  const showNextCard = ()=>{
    const tl = gsap.timeline({paused:true});
    tl.to('.dean-card',{autoAlpha:0},0);
    tl.to('.dean-card-'+ cardIndex, {autoAlpha:1});
    tl.play();

    // update the bottom nav


  }



  const closeDeanButtonClicked = () =>{
    eventBus.dispatch("hideDean");
    
  }

  const rightClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length+1) %  cards.length);  
    
  }

  const leftClicked = () =>{
   
    setCardIndex(  (cardIndex+cards.length-1) %  cards.length);  
    
  }

  
  return (
      <div className={'dean'} ref={dean}>
        <div className="dean-background"></div>
        <div className ="dean-cards">
            {cards.map((c,i)=>(
                <div key={i} className={`dean-card dean-card-${i}`}>
                  <div className="dean-card-title">{c.title}</div>
                  <div className="dean-card-text">{c.text}</div>
                  <img src={c.src} className="dean-card-image" alt="" />
                  <img src={c.srcm} className="dean-card-image-mobile" alt="" />
                  <img src={deanBack} className="dean-card-back" alt="" />
                  <img src={deanBackm} className="dean-card-back-mobile" alt="" />
                </div>
            )
        )}
        </div>
        <img ref={watercolorBackgroundRef} className="dean-watercolor-background" src={watercolorBackground} alt="" />
        <img onClick={leftClicked} className="dean-left-arrow" ref={leftArrow} src={LeftArrow} alt="" />
        <img onClick={rightClicked} className="dean-right-arrow" ref={rightArrow} src={RightArrow} alt="" />
         <img ref={closeDeanButton}  onClick={closeDeanButtonClicked} className="dean-close-button" src={deanCloseButton} />
         <div className="dean-nav">
            <div className="dean-nav-numbers">
            {cardIndex+1} of {cards.length}
            </div>
            <div className="dean-nav-text">
            &lt;&lt; CLICK TO EXPLORE &gt;&gt;
            </div>
         </div>
      </div>
  );
}

export default Dean;
// import icon from '../../assets/images/share.png';
import React,{ useEffect, useRef} from 'react';
// import eventBus from "../EventBus";
import './AdminLogin.css';
import submit from '../../assets/images/submit.png';
import logo from '../../assets/images/logo.png'

function AdminLogin() {

  const username = useRef();
  const password = useRef();

  useEffect(() => {

  }, []);

  const processForm = (e) =>{
    e.preventDefault();
    if(!username.current.value || !password.current.value) return

    const data = {username: username.current.value, password: password.current.value}
   
    fetch("/api/users", {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //mode: 'cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded',
      },
      //redirect: 'follow', // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.error) {
        alert("Incorrect username or password. Please check and try again.");
        return;
      }
      sessionStorage.setItem("user",{id:data.id,username:data.username});
      window.location.href = './admin';
    })
  }
   
  
  return (
    <div className="admin-login">
      <img src={logo} alt="logo" className="admin-logo" />
      <form onSubmit={processForm} id="admin-login-form" className="admin-login-form">
        <label htmlFor={"user-name"}>Username</label>
        <input required={true} ref={username} type="text" className='user-name' id='user-name' placeholder='user name' />
        <label htmlFor={"password"}>Password</label>
        <input required={true} ref={password} type="password" className='password' id='password' placeholder='password' />
        <button className='submit-button' id='admin-login-submit'><img src={submit} alt="submit" /></button>
      </form>
      {/* <p>{!data ? "Loading..." : JSON.stringify(data.users[0])}</p> */}
  </div>
  );
}

export default AdminLogin;
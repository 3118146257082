import './CTA.css';
//import media from '../assets/about-heart.png';
import React,{ useEffect, useRef} from 'react';
import eventBus from "../EventBus";

import gsap from 'gsap';

function CTA({roomName, room}) {

  const cta = useRef();
  const ctaShareButton = useRef();
  const ctaExecutiveButton = useRef();
  const ctaContactButton = useRef();
  const d1 = useRef();
  const d2 = useRef();

  useEffect(() => {
    eventBus.on("showCTA", (data) =>{
      // console.log("show CTA")

      window.view = 'cta';

      const tl = gsap.timeline({paused:true});
      tl.to(cta.current,{autoAlpha:1,duration:.5},(data.delay)?data.delay:0);
      //tl.to([ d1.current, ctaExecutiveButton.current, d2.current, ctaContactButton.current],{ stagger: 0.2, autoAlpha:1, x:0,y:0,duration:1},(data.delay)?data.delay:.3);
      tl.play();
    });

    eventBus.on("hideCTA", (data) =>{
      // console.log("hide CTA");
      // console.log("hide CTA / " + window.view + " / window.returnPath = " + window.returnPath);
      const tl = gsap.timeline({paused:true});
      tl.to(cta.current,{autoAlpha:0,duration:.5},0);
      //tl.to([ctaShareButton.current,d1.current , ctaExecutiveButton.current, d2.current, ctaContactButton.current],{ autoAlpha:0, duration:.5},0);
      // tl.call(()=>{
      //   switch(window.returnPath)
      //   {
      //     case "landing":

      //       eventBus.dispatch("showLandingEnter",{});
      //       eventBus.dispatch("showTopControls", { showBack:false, showMute:true, showShare:true });

      //       break;

      //     case "rotunda":  
      //     case "room1":
      //     case "room2":
      //     case "room3":

      //       eventBus.dispatch("showTopControls", { showBack:true, showMute:true, showShare:true });

      //       break;
      //   }
      // },null,2.5)
      tl.play();
    });

  }, [])

  const ctaShareButtonClicked = (e) =>{
    eventBus.dispatch("hideCTA");
    eventBus.dispatch("showSideBarBackButton")
    eventBus.dispatch("showShare");
  }

  const ctaContactButtonClicked = (e) =>{
    eventBus.dispatch("hideCTA");
    eventBus.dispatch("showSideBarBackButton")
    eventBus.dispatch("showContact");
  }

  const ctaExecutiveButtonClicked = (e) =>{
    eventBus.dispatch("hideCTA");
    eventBus.dispatch("showSideBarBackButton")
    eventBus.dispatch("showExecutive");
  }


  return (
      <div ref={cta} className={'cta'} >
      
        <button ref={ctaExecutiveButton} onClick={ctaExecutiveButtonClicked} className="cta-executive-button">Executive Summary</button>
        <div ref={d2} className="divider"></div>
        <button ref={ctaContactButton} onClick={ctaContactButtonClicked} className="cta-contact-button">Contact Us</button>
        <div ref={d1} className="divider"></div>
        <button ref={ctaShareButton} onClick={ctaShareButtonClicked} className="cta-share-button">Share This Website</button>
        
      </div>
  );
}

export default CTA;
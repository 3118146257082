import './Landing.css';
import buildings from '../../assets/images/buildings.jpg';
import HorseLogo from '../../assets/images/horse-logo.svg';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';
import ReactPlayer from 'react-player';

import ShowLandingVideo from '../../assets/videos/show-landing.mp4'
import HideLandingVideo from '../../assets/videos/hide-landing.mp4'

function Landing() {

  const [playingShowVideo, setPlayingShowVideo] = useState(false);
  const [playingHideVideo, setPlayingHideVideo] = useState(false);

  const landing = useRef();

  const landingShowVideo = useRef();
  const landingShowVideoVideo = useRef();
  const landingHideVideo = useRef();
  const landingHideVideoVideo = useRef();
  
  const enterButton = useRef();

  /////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    eventBus.on("showLanding", (data) =>{
      
      // if(!data){data={};}
      // console.log("showLanding");
      
      /////////////////////////////////////////////////////////////////////////////////

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': 'landing',
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / landing");
        // console.log("virtualPageView / window.path = " + window.path);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      gsap.killTweensOf([landing.current, landingShowVideo.current, landingHideVideo.current, enterButton.current]);

      landingShowVideoVideo.current.seekTo(0);
      setPlayingShowVideo(false);
      landingHideVideoVideo.current.seekTo(0);
      setPlayingHideVideo(false);

      const tl = gsap.timeline({paused:true});
      tl.set(landingShowVideo.current,{autoAlpha:0},0);
      tl.set(landingHideVideo.current,{autoAlpha:0},0);
      tl.to(landing.current,{autoAlpha:1,duration:.5},0);//(data.delay)?data.delay:
      tl.to(landingShowVideo.current,{autoAlpha:1,duration:1},1);
      tl.call(()=>{
        landingShowVideoVideo.current.seekTo(0);
        setPlayingShowVideo(true);
      },null,.5)
      tl.call(()=>{
        if(!window.sideBarLoaded){
          eventBus.dispatch("showSideBar",{delay:0});
          window.sideBarLoaded = true;
        }
        // eventBus.dispatch("showSiteControls",{delay:0});
        
        // update teh brwowser histery/url bar
        if(window.path!="landing"){
         // window.history.pushState({},'','./landing');
        }
      },null,3.5)
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:false, showSkip:false, showMute:true, showShare:true})//, delay:4.5
      },null,4.5)
      tl.fromTo(enterButton.current,{autoAlpha:0},{autoAlpha:1},4.5);

      // tl.call(()=>{
      //   eventBus.dispatch("showClickBlocker", {});
      // },null,7)
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideLanding", (data) =>{
      // console.log("hide landing")
      
      gsap.killTweensOf([landingShowVideo.current, landingHideVideo.current, enterButton.current]);

      landingHideVideoVideo.current.seekTo(0);
      setPlayingHideVideo(true);

      const tl = gsap.timeline({paused:true});
      tl.to(landingShowVideo.current,{autoAlpha:0,duration:.5},0);
      tl.to(landingHideVideo.current,{autoAlpha:1,duration:.5},0);
      tl.to(enterButton.current,{autoAlpha:0},0);
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("showLandingEnter", (data) =>{
      gsap.killTweensOf([enterButton.current]);

      const tl = gsap.timeline({paused:true});
      tl.to(enterButton.current,{autoAlpha:1,duration:.5}, 0);
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideLandingEnter", (data) =>{
      gsap.killTweensOf([enterButton.current]);

      const tl = gsap.timeline({paused:true});
      tl.to(enterButton.current,{autoAlpha:0,duration:.5});
      tl.play();
    });

  }, [])

  /////////////////////////////////////////////////////////////////////////////////

  const landingShowVideoEnded=()=>{
    // const tl = gsap.timeline({paused:true});
    // tl.play();
  }

  /////////////////////////////////////////////////////////////////////////////////

  const landingHideVideoEnded=()=>{
  //console.log('landingHideVideoEnded');

    gsap.killTweensOf([landing.current, landingHideVideo.current]);

    const tl = gsap.timeline({paused:true});
    tl.set(landingShowVideo.current,{autoAlpha:0},0);
    tl.to(landing.current,{autoAlpha:0,duration:.5},0);
    tl.to(landingHideVideo.current,{autoAlpha:0,duration:.5},0);
    tl.play();
  }

  /////////////////////////////////////////////////////////////////////////////////

  const enterClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'rotunda',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / rotunda");
      // console.log("virtualPageView / window.path = " + window.path);

    },100)

    eventBus.dispatch("hideLanding", {});
    eventBus.dispatch("showRotunda", {});
    eventBus.dispatch("hideLandingEnter", {});
    eventBus.dispatch("updateSideBarContent", {path:"rotunda",delay:5});

    eventBus.dispatch("enableClickBlocker");
  }

  /////////////////////////////////////////////////////////////////////////////////

  return (
      <div className="landing" ref={landing}>
        <button ref={enterButton} onClick={enterClicked} className="landing-enter-button">Enter <img src={HorseLogo} alt="" /></button>
        <div className={"landing-show-video"} ref={landingShowVideo}>
          <ReactPlayer
            ref = {landingShowVideoVideo}
            playsinline ={true}
            muted={true}
            playing ={playingShowVideo}
            loop={false}
            width={"100%"}
            height={"100%"}
            className="landing-show-video-video" 
            url={ShowLandingVideo}
            onEnded = {landingShowVideoEnded}
          />
        </div>
        <div className={"landing-hide-video"} ref={landingHideVideo}>
          <ReactPlayer
            ref={landingHideVideoVideo}
            playsinline ={true}
            muted={true}
            playing ={playingHideVideo}
            loop={false}
            width={"100%"}
            height={"100%"}
            className="landing-hide-video-video" 
            url={HideLandingVideo}
            onEnded = {landingHideVideoEnded}
          />
        </div>
        
      </div>

  );
}

export default Landing;
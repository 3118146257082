import './Scene3D.css';
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Environment, OrbitControls, PerspectiveCamera, Plane, Sphere, Billboard, useTexture } from '@react-three/drei';
import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";
import rotunda from '../../assets/images/rotunda.jpg'
import image360 from '../../assets/images/360.png'
import room1 from '../../assets/images/room-1.jpg'
import room2 from '../../assets/images/room-2.jpg'
import room3 from '../../assets/images/room-3.jpg'
import room1bw from '../../assets/images/room-1-bw.jpg'
import room2bw from '../../assets/images/room-2-bw.jpg'
import room3bw from '../../assets/images/room-3-bw.jpg'
import gallery1 from '../../assets/images/3d-buttons/pics/pic-1.png'
import gallery2 from '../../assets/images/3d-buttons/pics/pic-2.png'
import gallery3 from '../../assets/images/3d-buttons/pics/pic-3.png'
import gallery4 from '../../assets/images/3d-buttons/pics/pic-4.png'
import key from '../../assets/images/3d-buttons/youreTheKeyBtn.png'
import discover from '../../assets/images/3d-buttons/discoverYourFutureBtn.png'
import success from '../../assets/images/3d-buttons/studentSuccessBtn.png'

import room1ButtonImage from '../../assets/images/3d-buttons/clusterClassroomBtn.png'
import room2ButtonImage from '../../assets/images/3d-buttons/lectureHallBtn.png'
import room3ButtonImage from '../../assets/images/3d-buttons/teamRoomsBtn.png'
import donorButtonImage from '../../assets/images/3d-buttons/trusteesMessageBtn.png'
import donorPic from '../../assets/images/3d-buttons/pics/donor-pic.png'
import deanButtonImage from '../../assets/images/3d-buttons/deansMessageBtn.png'
import deanPic from '../../assets/images/3d-buttons/pics/dean-pic.png'
import galleryButtonImage from '../../assets/images/3d-buttons/imageGalleryBtn.png'

import * as THREE from "three";
import gsap from 'gsap';


function Scene3D() {

  // use this to adjust red 3D buttons uniformly 
  const buttonScale = .065;

  const myCamera = useRef()

  const room1Button = useRef()
  const room2Button = useRef()
  const room3Button = useRef()
  const donorButton = useRef()
  const deanButton = useRef()
  const donorPicButton = useRef()
  const deanPicButton = useRef()
  const galleryButton = useRef()

  const galleryButton1 = useRef()
  const galleryButton2 = useRef()
  const galleryButton3 = useRef()
  const galleryButton4 = useRef()

  const keyButton1 = useRef()
  const discoverButton1 = useRef()
  const successButton1 = useRef()
  const keyButton2 = useRef()
  const discoverButton2 = useRef()
  const successButton2 = useRef()
  const keyButton3 = useRef()
  const discoverButton3 = useRef()
  const successButton3 = useRef()
  const scene = useRef()
  const controls = useRef();
  const image360Ref = useRef();
  // const roomMat = useRef();

  let lookDirection = new THREE.Vector3();

  useEffect(() => {

    rotundaMap.repeat.x = - 1;
    rotundaMap.wrapS = THREE.RepeatWrapping; 
    rotundaMap.center.set(.035,0);
    
    //

    room1Map.repeat.x = -1;
    room1Map.wrapS = THREE.RepeatWrapping; 
    room1Map.center.set(.125,0);

    room2Map.repeat.x = -1;
    room2Map.wrapS = THREE.RepeatWrapping; 
    room2Map.center.set(.125,0);
    
    room3Map.repeat.x = -1;
    room3Map.wrapS = THREE.RepeatWrapping; 
    room3Map.center.set(.125,0);

    room1bwMap.repeat.x = -1;
    room1bwMap.wrapS = THREE.RepeatWrapping; 
    room1bwMap.center.set(.125,0);
    
    room2bwMap.repeat.x = -1;
    room2bwMap.wrapS = THREE.RepeatWrapping; 
    room2bwMap.center.set(.125,0);
    
    room3bwMap.repeat.x = -1;
    room3bwMap.wrapS = THREE.RepeatWrapping; 
    room3bwMap.center.set(.125,0);

    //

    // setTimeout(()=>{
    //   room1Button.current.lookAt(0,0,0);
    //   room2Button.current.lookAt(0,0,0);
    //   room3Button.current.lookAt(0,0,0);
    //   galleryButton1.current.lookAt(0,0,0);
    //   galleryButton2.current.lookAt(0,0,0);
    //   galleryButton3.current.lookAt(0,0,0);
    //   galleryButton4.current.lookAt(0,0,0);
    //   galleryButton.current.lookAt(0,0,0);
    //   donorButton.current.lookAt(0,0,0);
    //   deanButton.current.lookAt(0,0,0);
    //   donorPicButton.current.lookAt(0,0,0);
    //   deanPicButton.current.lookAt(0,0,0);
    //   keyButton1.current.lookAt(0,0,0);
    //   successButton1.current.lookAt(0,0,0);
    //   discoverButton1.current.lookAt(0,0,0);
    //   keyButton2.current.lookAt(0,0,0);
    //   successButton2.current.lookAt(0,0,0);
    //   discoverButton2.current.lookAt(0,0,0);
    //   keyButton3.current.lookAt(0,0,0);
    //   successButton3.current.lookAt(0,0,0);
    //   discoverButton3.current.lookAt(0,0,0);
    // }, 8000);

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("showRotunda", (data) =>{

      room1Button.current.lookAt(0,0,0);
      room2Button.current.lookAt(0,0,0);
      room3Button.current.lookAt(0,0,0);
      galleryButton1.current.lookAt(0,0,0);
      galleryButton2.current.lookAt(0,0,0);
      galleryButton3.current.lookAt(0,0,0);
      galleryButton4.current.lookAt(0,0,0);
      galleryButton.current.lookAt(0,0,0);
      donorButton.current.lookAt(0,0,0);
      deanButton.current.lookAt(0,0,0);
      donorPicButton.current.lookAt(0,0,0);
      deanPicButton.current.lookAt(0,0,0);
      keyButton1.current.lookAt(0,0,0);
      successButton1.current.lookAt(0,0,0);
      discoverButton1.current.lookAt(0,0,0);
      keyButton2.current.lookAt(0,0,0);
      successButton2.current.lookAt(0,0,0);
      discoverButton2.current.lookAt(0,0,0);
      keyButton3.current.lookAt(0,0,0);
      successButton3.current.lookAt(0,0,0);
      discoverButton3.current.lookAt(0,0,0);

      // console.log("show rotunda")
      eventBus.dispatch("hideTopControls",{});
      window.view = "";
      window.section = "rotunda";

      // console.log("1a showRotunda / myCamera.current.rotation = " + myCamera.current.rotation.x + " / " + myCamera.current.rotation.y + " / " + myCamera.current.rotation.z );

      // gsap.killTweensOf([myCamera.current.position, '.scene3D']);

      const tl = gsap.timeline({paused:true});
      // tl.fromTo(roomMat.current,{opacity:1},{opacity:0,duration:1.0},(data.delay+2.5)?data.delay:7.5)
      tl.call(()=>{
        setActiveMap(rotundaMap);
        //controls.current.enabled=true;
        controls.current.reset();
        controls.current.update();
        controls.current.enabled = false;
        
        // show buttons from rotunda
        deanButton.current.scale.set(1,1,1);
        donorButton.current.scale.set(1,1,1);
        deanPicButton.current.scale.set(1,1,1);
        donorPicButton.current.scale.set(1,1,1);
        galleryButton.current.scale.set(1,1,1);
        galleryButton1.current.scale.set(1,1,1);
        galleryButton2.current.scale.set(1,1,1);
        galleryButton3.current.scale.set(1,1,1);
        galleryButton4.current.scale.set(1,1,1);

        if(window.showRoom1){
          room1Button.current.scale.set(1,1,1); 
        } else  {
          room1Button.current.scale.set(0,0,0); 
        }

        if(window.showRoom2){ 
          room2Button.current.scale.set(1,1,1); 
        } else  {
          room2Button.current.scale.set(0,0,0); 
        }

        if(window.showRoom3){ 
          room3Button.current.scale.set(1,1,1); 
        } else  {
          room3Button.current.scale.set(0,0,0); 
        }
        
        
        // hide buttons for room
        keyButton1.current.scale.set(0,0,0);
        successButton1.current.scale.set(0,0,0);
        discoverButton1.current.scale.set(0,0,0);
        keyButton2.current.scale.set(0,0,0);
        successButton2.current.scale.set(0,0,0);
        discoverButton2.current.scale.set(0,0,0);
        keyButton3.current.scale.set(0,0,0);
        successButton3.current.scale.set(0,0,0);
        discoverButton3.current.scale.set(0,0,0);
        
      },null,(data.delay)?data.delay:0)

      tl.call(()=>{
        eventBus.dispatch("showMobileContent",{});
        if(window.innerWidth>window.innerHeight){
          eventBus.dispatch("show360Image",{});
        }
      },null,(data.delay+.5)?data.delay:5)
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showShare:true, showMute:true});
      },null,(data.delay)?data.delay+.5:6)
  
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,(data.delay)?data.delay+1:6.5)

      // tl.set(myCamera.current.rotation,{y:-1,duration:2},0);
      tl.set(myCamera.current.position,{z:-15},(data.delay)?data.delay:0);
      tl.to(myCamera.current.position,{z:.01,duration:2, ease: "Quad.easeInOut",onComplete:()=>{
        // console.log("1b showRotunda / myCamera.current.rotation = " + myCamera.current.rotation.x + " / " + myCamera.current.rotation.y + " / " + myCamera.current.rotation.z );
        controls.current.enabled = true;
        myCamera.current.getWorldDirection( lookDirection );
        controls.current.target.copy( myCamera.current.position ).add( lookDirection.multiplyScalar( .01 ) );
        // console.log("1c showRotunda / myCamera.current.rotation = " + myCamera.current.rotation.x + " / " + myCamera.current.rotation.y + " / " + myCamera.current.rotation.z );
      }},(data.delay)?data.delay:6);
      tl.to('.scene3D',{autoAlpha:1,duration:.5},(data.delay+1)?data.delay:6);

      tl.play();

    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideRotunda", (data) =>{
      // console.log("hide rotunda")
      eventBus.dispatch("hideTopControls",{});
      // gsap.killTweensOf([myCamera.current.position, '.scene3D']);
      
      // console.log("myCamera.current.position = " + myCamera.current.position.x + " / " + myCamera.current.position.y + " / " + myCamera.current.position.z );
      // console.log("hideRotunda / myCamera.current.rotation = " + myCamera.current.rotation.x + " / " + myCamera.current.rotation.y + " / " + myCamera.current.rotation.z );

      const tl = gsap.timeline({paused:true});
      if(data && data.room){
        tl.to('.scene3D',{autoAlpha:0,duration:2},0);
        tl.call(()=>{
          controls.current.enabled = false;
        },null,0)
        tl.to(myCamera.current.position,{
          x:data.room.current.position.x *.3, 
          z:data.room.current.position.z *.3,  
          ease: "Quad.easeInOut",
          duration:2},0);
      }
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("showRoom", (data) =>{
      // console.log("show Room ", data.room)
      window.view = "";
      window.section = "room_"+data.room;

      eventBus.dispatch("enableClickBlocker");
     
      // gsap.killTweensOf([myCamera.current.position, '.scene3D']);

      const delay = (data.delay)?data.delay:0;
      const tl = gsap.timeline({paused:true});
      tl.call(()=>{
        controls.current.enabled=true;
        controls.current.reset();
        controls.current.update();
        controls.current.enabled=false;
        
        // hide buttons from rotunda
        deanButton.current.scale.set(0,0,0);
        donorButton.current.scale.set(0,0,0);
        deanPicButton.current.scale.set(0,0,0);
        donorPicButton.current.scale.set(0,0,0);
        galleryButton.current.scale.set(0,0,0);
        galleryButton1.current.scale.set(0,0,0);
        galleryButton2.current.scale.set(0,0,0);
        galleryButton3.current.scale.set(0,0,0);
        galleryButton4.current.scale.set(0,0,0);
        room1Button.current.scale.set(0,0,0);
        room2Button.current.scale.set(0,0,0);
        room3Button.current.scale.set(0,0,0);

        // hide buttons for room
        keyButton1.current.scale.set(0,0,0);
        successButton1.current.scale.set(0,0,0);
        discoverButton1.current.scale.set(0,0,0);
        keyButton2.current.scale.set(0,0,0);
        successButton2.current.scale.set(0,0,0);
        discoverButton2.current.scale.set(0,0,0);
        keyButton3.current.scale.set(0,0,0);
        successButton3.current.scale.set(0,0,0);
        discoverButton3.current.scale.set(0,0,0);
        
        switch(data.room){
          case 1:
            if(window.unlocked){
              setActiveMap(room1Map);
            } else {
              setActiveMap(room1bwMap);
            }
            // show buttons for room
            keyButton1.current.scale.set(1,1,1);
            successButton1.current.scale.set(1,1,1);
            discoverButton1.current.scale.set(1,1,1);
            break;
          case 2:
            if(window.unlocked){
              setActiveMap(room2Map);
            } else {
              setActiveMap(room2bwMap);
            }
            // show buttons for room
            keyButton2.current.scale.set(1,1,1);
            successButton2.current.scale.set(1,1,1);
            discoverButton2.current.scale.set(1,1,1);
            break;
          case 3:
            if(window.unlocked){
              setActiveMap(room3Map);
            } else {
              setActiveMap(room3bwMap);
            }
            // show buttons for room
            keyButton3.current.scale.set(1,1,1);
            successButton3.current.scale.set(1,1,1);
            discoverButton3.current.scale.set(1,1,1);
            break;
          default:
            break;
        }
        eventBus.dispatch("showMobileContent",{});
        
        tl.call(()=>{
          eventBus.dispatch("showTopControls", { showBack:true, showMute:true, showShare:true });
        },null,3)
        tl.call(()=>{
          eventBus.dispatch("disableClickBlocker");
        },null,4)

      },null,delay)
      tl.set(myCamera.current.position,{z:-15,x:0,y:0},delay+.1);
      window.rotation={}; window.position={};
      window.rotation.x = myCamera.current.rotation.x;
      window.rotation.y = myCamera.current.rotation.y;
      window.rotation.z = myCamera.current.rotation.z;
      window.position.x = myCamera.current.position.x;
      window.position.y = myCamera.current.position.y;
      window.position.z = myCamera.current.position.z;

      tl.to(myCamera.current.position,{z:.01, ease: "Quad.easeInOut", duration:2,onComplete:()=>{
        if(window.unlocked){
          controls.current.enabled = true;
          myCamera.current.getWorldDirection( lookDirection );
          controls.current.target.copy( myCamera.current.position ).add( lookDirection.multiplyScalar( .01 ) );
        }
      }},delay+.1);
      tl.to('.scene3D',{autoAlpha:1,duration:1},delay+.1);
      tl.play();

      if(window.innerWidth>window.innerHeight){
        if(window.unlocked){
          eventBus.dispatch("show360Image",{});
        } else {
          eventBus.dispatch("hide360Image",{});
        }
      }
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideRoom", (data) =>{
      console.log("hide room")

      // gsap.killTweensOf([myCamera.current.position, '.scene3D']);

      const tl = gsap.timeline({paused:true});
      tl.to(myCamera.current.position,{
        z: myCamera.current.position.z * -1000,
        x: myCamera.current.position.x * -1000,
        ease: "Quad.easeInOut", 
        duration:1.5},0);

      tl.to('.scene3D',{autoAlpha:0,duration:1.5},0);
      tl.call(()=>{
        controls.current.enabled = false;
      },null,0)
      tl.call(()=>{
        myCamera.current.rotation.x = window.rotation.x;
        myCamera.current.rotation.y = window.rotation.y;
        myCamera.current.rotation.z = window.rotation.z;
        myCamera.current.position.x = window.position.x;
        myCamera.current.position.y = window.position.y;
        myCamera.current.position.z = window.position.z;
      },null,1.5)
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("show360Image", (data) =>{
      if(!window.unlocked) return;
      gsap.killTweensOf([image360Ref.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(image360Ref.current,{autoAlpha:1,duration:.5});
      tl.play();
    })

    eventBus.on("hide360Image", (data) =>{
      gsap.killTweensOf([image360Ref.current]);
      const tl = gsap.timeline({paused:true});
      tl.to(image360Ref.current,{autoAlpha:0,duration:.5});
      tl.play();
    })

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideKey", data =>{ 
      eventBus.dispatch("show360Image");
      if(window.section==="room_1"){setActiveMap(room1Map); }
      if(window.section==="room_2"){setActiveMap(room2Map); }
      if(window.section==="room_3"){setActiveMap(room3Map); }
      controls.current.enabled = true;
    });
  }, [])
  
  /////////////////////////////////////////////////////////////////////////////////

  const room1Map = useTexture(room1)
  const room2Map = useTexture(room2)
  const room3Map = useTexture(room3)
  const room1bwMap = useTexture(room1bw)
  const room2bwMap = useTexture(room2bw)
  const room3bwMap = useTexture(room3bw)
  const rotundaMap = useTexture(rotunda)
  const donorMap = useTexture(donorButtonImage);
  const deanMap = useTexture(deanButtonImage);
  const donorPicMap = useTexture(donorPic);
  const deanPicMap = useTexture(deanPic);
  const keyMap = useTexture(key);
  const successMap = useTexture(success);
  const discoverMap = useTexture(discover);
  const gallery1Map = useTexture(gallery1);
  const gallery2Map = useTexture(gallery2);
  const gallery3Map = useTexture(gallery3);
  const gallery4Map = useTexture(gallery4);
  const galleryBtnMap = useTexture(galleryButtonImage);
  const room1ButtonMap = useTexture(room1ButtonImage);
  const room2ButtonMap = useTexture(room2ButtonImage);
  const room3ButtonMap = useTexture(room3ButtonImage);

  const [activeMap, setActiveMap]=useState(rotundaMap);
  const [keyMapState, setMapState]=useState(keyMap);

  const enterRoom1ButtonClicked = (e) =>{
    //alert("enter room 1 clicked")
    eventBus.dispatch("hideRotunda", {"room":room1Button});
    eventBus.dispatch("showRoom", {"room":1, delay:2});
    eventBus.dispatch("updateSideBarContent", {path:"room1",delay:2});

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'roomCluster',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / roomCluster");
      // console.log("virtualPageView / window.path = " + window.path);

    },100)

  }

  const enterRoom2ButtonClicked = (e) =>{
    //alert("enter room 2 clicked")
    eventBus.dispatch("hideRotunda", {"room":room2Button});
    eventBus.dispatch("showRoom", {"room":2, delay:2});
    eventBus.dispatch("updateSideBarContent", {path:"room2",delay:2});

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'roomLecture',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / roomLecture");
      // console.log("virtualPageView / window.path = " + window.path);

    },100)

  }

  const enterRoom3ButtonClicked = (e) =>{
    //alert("enter room 3 clicked")
    eventBus.dispatch("hideRotunda", {"room":room3Button});
    eventBus.dispatch("showRoom", {"room":3, delay:2});
    eventBus.dispatch("updateSideBarContent", {path:"room3",delay:2});

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'roomTeam',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / roomTeam");
      // console.log("virtualPageView / window.path = " + window.path);

    },100)
  }

  const galleryButtonClicked = (e) =>{
    eventBus.dispatch("showGallery", {});
    eventBus.dispatch("updateSideBarContent",{path:"gallery"});
  }

  const deanButtonClicked = (e) =>{
    eventBus.dispatch("showDean", {});
    eventBus.dispatch("updateSideBarContent",{path:"dean"});
  }

  const donorButtonClicked = (e) =>{
    eventBus.dispatch("showDonor", {});
    eventBus.dispatch("updateSideBarContent",{path:"donor"});
  }

  const keyButton1Clicked = (e) =>{
    eventBus.dispatch("showKey", {room:1});
    eventBus.dispatch("updateSideBarContent",{path:"key", room:1});
  }

  const discoverButton1Clicked = (e) =>{
    eventBus.dispatch("showDiscover", {room:1});
    eventBus.dispatch("updateSideBarContent",{path:"discover", room:1});
  }

  const successButton1Clicked = (e) =>{
    eventBus.dispatch("showSuccess", {room:1});
    eventBus.dispatch("updateSideBarContent",{path:"success", room:1});
  }

  const keyButton2Clicked = (e) =>{
    eventBus.dispatch("showKey", {room:2});
    eventBus.dispatch("updateSideBarContent",{path:"key", room:2});
  }

  const discoverButton2Clicked = (e) =>{
    eventBus.dispatch("showDiscover", {room:2});
    eventBus.dispatch("updateSideBarContent",{path:"discover", room:2});
  }

  const successButton2Clicked = (e) =>{
    eventBus.dispatch("showSuccess", {room:2});
    eventBus.dispatch("updateSideBarContent",{path:"success", room:2});
  }

  const keyButton3Clicked = (e) =>{
    eventBus.dispatch("showKey", {room:3});
    eventBus.dispatch("updateSideBarContent",{path:"key", room:3});
  }

  const discoverButton3Clicked = (e) =>{
    eventBus.dispatch("showDiscover", {room:3});
    eventBus.dispatch("updateSideBarContent",{path:"discover", room:3});
  }

  const successButton3Clicked = (e) =>{
    eventBus.dispatch("showSuccess", {room:3});
    eventBus.dispatch("updateSideBarContent",{path:"success", room:3});
  }
  
  const [hovered, setHovered] = useState(false)
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'

  }, [hovered])
  
  return (
    <div className="scene3D" >
    <Canvas
      gl = {{ 
      transparent: false,
      antialias:true,
      pixelRatio:3,  
    }}
    camera = {myCamera}
    ref={scene}
  >
    <PerspectiveCamera fov={60} position={[0, 0, 0]} ref={myCamera} makeDefault ></PerspectiveCamera>
    {
      <OrbitControls 
        camera={myCamera.current} 
        enablePan={false}
        minDistance={.01}
        maxDistance={1}
        minPolarAngle={1.55}
        maxPolarAngle={1.65}
        target={[0, 0, 0]}
        enableZoom={false}
        rotateSpeed = {-.35}
        ref={controls}
      />
    }

    <Sphere args={[100,32,32]}>
      <meshBasicMaterial map={activeMap} attach="material" side={THREE.BackSide} />
        <Plane 
          ref={room1Button} 
          scale={[1,1,1]}
          onClick={enterRoom1ButtonClicked} 
          args={[745*buttonScale,144*buttonScale]} 
          position={[4.5,16,-90]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthWrite={false} map={room1ButtonMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={room2Button} 
          onClick={enterRoom2ButtonClicked} 
          args={[575*buttonScale,144*buttonScale]} 
          position={[76,16,49]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)} 
        >
          <meshBasicMaterial depthWrite={false} map={room2ButtonMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={room3Button} 
          onClick={enterRoom3ButtonClicked} 
          args={[586*buttonScale,144*buttonScale]}  
          position={[-77.5,16,40]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={room3ButtonMap} transparent={true} opacity={1} />
        </Plane>

        <Plane 
          ref={galleryButton1} 
          onClick={galleryButtonClicked} 
          args={[296*buttonScale, 220*buttonScale]}
          position={[85*Math.sin(Math.PI/180*147.5),1.8,85*Math.cos(Math.PI/180*147.5)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={gallery1Map} transparent={true} opacity={1} depthTest={false} depthWrite={false}  />
        </Plane>
        <Plane 
          ref={galleryButton2} 
          onClick={galleryButtonClicked} 
          args={[296*buttonScale, 220*buttonScale]}
          position={[85*Math.sin(Math.PI/180*29),1.8,85*Math.cos(Math.PI/180*29)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={gallery2Map} transparent={true} opacity={1} depthTest={false} depthWrite={false}  />
        </Plane>
        <Plane 
          ref={galleryButton3} 
          onClick={galleryButtonClicked} 
          args={[296*buttonScale, 220*buttonScale]}
          position={[85*Math.sin(Math.PI/180*325),1.8,85*Math.cos(Math.PI/180*325)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={gallery3Map} transparent={true} opacity={1} depthTest={false} depthWrite={false} />
        </Plane>
        <Plane 
          ref={galleryButton4} 
          onClick={galleryButtonClicked} 
          args={[296*buttonScale, 220*buttonScale]}
          position={[85*Math.sin(Math.PI/180*239),1.8,85*Math.cos(Math.PI/180*239)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={gallery4Map} transparent={true} opacity={1} depthTest={false} depthWrite={false}  />
        </Plane>

        <Plane 
           ref={galleryButton}
           onClick={galleryButtonClicked} 
           args={[276*buttonScale,80*buttonScale]} 
           position={[-46.3,12.5,66]}
           onPointerOver={() => setHovered(true)}
           onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={galleryBtnMap}  transparent={true} opacity={1} />
        </Plane>

        <Plane 
           ref={donorButton}
           onClick={donorButtonClicked} 
           args={[340*buttonScale, 80*buttonScale]} 
           position={[-35,13.5,-82]}
           onPointerOver={() => setHovered(true)}
           onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={donorMap}  transparent={true} opacity={1} />
        </Plane>
        <Plane 
           ref={donorPicButton}
           onClick={donorButtonClicked} 
           args={[220*buttonScale,297*buttonScale]} 
           position={[-35,-.5,-82]}
           onPointerOver={() => setHovered(true)}
           onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={donorPicMap}  transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={deanButton} 
          onClick={deanButtonClicked} 
          args={[296*buttonScale, 80*buttonScale]} 
          position={[86,13.2,14]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={deanMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={deanPicButton} 
          onClick={deanButtonClicked} 
          args={[220*buttonScale,297*buttonScale]} 
          position={[88,-.6,14.5]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial map={deanPicMap} transparent={true} opacity={1} />
        </Plane>



        <Plane 
          ref={keyButton1} 
          onClick={keyButton1Clicked} 
          args={[680*buttonScale, 152*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*-180),11,85*Math.cos(Math.PI/180*-180)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={keyMapState} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={successButton1} 
          onClick={successButton1Clicked} 
          args={[676*buttonScale, 144*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*70),8,85*Math.cos(Math.PI/180*70)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={successMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={discoverButton1} 
          onClick={discoverButton1Clicked} 
          args={[822 * buttonScale, 144 * buttonScale]} 
          position={[85*Math.sin(Math.PI/180*290),8,85*Math.cos(Math.PI/180*290)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={discoverMap} transparent={true} opacity={1} />
        </Plane>

        <Plane 
          ref={keyButton2} 
          onClick={keyButton2Clicked} 
          args={[680*buttonScale, 152*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*-180),11,85*Math.cos(Math.PI/180*-180)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={keyMapState} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={successButton2} 
          onClick={successButton2Clicked} 
          args={[676*buttonScale, 144*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*70),8,85*Math.cos(Math.PI/180*70)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={successMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={discoverButton2} 
          onClick={discoverButton2Clicked} 
          args={[822 * buttonScale, 144 * buttonScale]} 
          position={[85*Math.sin(Math.PI/180*290),8,85*Math.cos(Math.PI/180*290)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={discoverMap} transparent={true} opacity={1} />
        </Plane>
        

        <Plane 
          ref={keyButton3} 
          onClick={keyButton3Clicked} 
          args={[680*buttonScale, 152*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*-180),11,85*Math.cos(Math.PI/180*-180)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={keyMapState} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={successButton3} 
          onClick={successButton3Clicked} 
          args={[676*buttonScale, 144*buttonScale]} 
          position={[85*Math.sin(Math.PI/180*70),8,85*Math.cos(Math.PI/180*70)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={successMap} transparent={true} opacity={1} />
        </Plane>
        <Plane 
          ref={discoverButton3} 
          onClick={discoverButton3Clicked} 
          args={[822 * buttonScale, 144 * buttonScale]} 
          position={[85*Math.sin(Math.PI/180*290),8,85*Math.cos(Math.PI/180*290)]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
        >
          <meshBasicMaterial depthTest={false} depthWrite={false} map={discoverMap} transparent={true} opacity={1} />
        </Plane>

        
        
    </Sphere>
    {/* <ambientLight intensity={1} />
     */}
   
      
      {/* <Environment
        background={false} // Whether to affect scene.background
       preset={"city"} // Preset string (overrides files and path)
      // files={room1HDR}
      // preset={null}
      /> */}
     
    </Canvas>
    <img src={image360} alt="" className="image-360" ref={image360Ref} />
  </div>
  );
}

export default Scene3D;
import './Discover.css';
import DiscoverCloseButton from '../../assets/images/close-button.svg';
import quote from '../../assets/images/quote.svg';
import discoverImage1a from '../../assets/images/discover-1a.jpg';
import discoverImage1b from '../../assets/images/discover-1b.jpg';
import discoverImage2a from '../../assets/images/discover-2a.jpg';
import discoverImage2b from '../../assets/images/discover-2b.jpg';
import discoverImage3a from '../../assets/images/discover-3a.jpg';
import discoverImage3b from '../../assets/images/discover-3b.jpg';
import React,{ useEffect, useRef } from 'react';
import eventBus from "../EventBus";
import gsap from 'gsap';


function Discover() {  


  const closeDiscoverButton = useRef();
  const discover = useRef();
  const discoverContent1 = useRef();
  const discoverContent2 = useRef();
  const discoverContent3 = useRef();


  useEffect(() => {
    eventBus.on("showDiscover", (data) =>{
      // console.log("show discover", data )

      /////////////////////////////////////////////////////////////////////////////////

      window.view = "discover";

      // window.history.pushState({},'','./landing');

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': window.view,
          'username': (window.username) ? window.username : 'publicVisitor'
        });

        console.log("virtualPageView / " + window.view);

      },100)

      /////////////////////////////////////////////////////////////////////////////////

      eventBus.dispatch("enableClickBlocker");
      eventBus.dispatch("hideTopControls");
      const tl = gsap.timeline({paused:true});
      tl.to([discover.current],{ autoAlpha:1, duration:.5},.3);
      tl.to([ closeDiscoverButton.current],{ autoAlpha:1, duration:.5},.3);
      switch(data.room){
        case 1:
          tl.set([discoverContent1.current],{ display:"block"},0);
          tl.set([discoverContent2.current],{ display:"none"},0);
          tl.set([discoverContent3.current],{ display:"none"},0);
          break;
        case 2:
          tl.set([discoverContent1.current],{ display:"none"},0);
          tl.set([discoverContent2.current],{ display:"block"},0);
          tl.set([discoverContent3.current],{ display:"none"},0);
          break;
        case 3:
          tl.set([discoverContent1.current],{ display:"none"},0);
          tl.set([discoverContent2.current],{ display:"none"},0);
          tl.set([discoverContent3.current],{ display:"block"},0);
          break;
        default:
          break;
      }
      tl.call(()=>{
        eventBus.dispatch("disableClickBlocker");
      },null,1)
      tl.play();
      eventBus.dispatch("showCloseButtonMobile",{});
    });

    eventBus.on("hideDiscover", data =>{
      // console.log("hide Discover");
      // eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      const tl = gsap.timeline({paused:true});
      tl.to([discover.current],{ autoAlpha:0, duration:.5},0);
      tl.to([ closeDiscoverButton.current],{ autoAlpha:0, duration:.5},0);
      tl.call(()=>{
        eventBus.dispatch("showTopControls",{showBack:true, showMute:true, showShare:true});
      },null,1)
      tl.play();

      //

      eventBus.dispatch("hideCloseButtonMobile",{});
    })


    eventBus.on("closeButtonMobileClicked", data =>{
      if(window.view==="discover"){
        eventBus.dispatch("hideDiscover");
      }
    });
  
    // eventBus.on("hideMobileContent", data =>{
    //   const tl = gsap.timeline({paused:true});
    //   tl.to(['.donor-cards',leftArrow.current, rightArrow.current, '.donor-nav'],{ autoAlpha:1, duration:1},.5);
    //   tl.play();
    // });


  }, [])



  const closeDiscoverButtonClicked = () =>{
    eventBus.dispatch("hideDiscover");
    
  }



  return (
      <div className={'discover'} ref={discover}>
         <div className="discover-background"></div>
         <img ref={closeDiscoverButton}  onClick={closeDiscoverButtonClicked} className="discover-close-button" src={DiscoverCloseButton} />
         <div className="discover-content">
           <div className={`discover-content-1 discover-content-container`} ref={discoverContent1}>
             <div className="grey-box1">
                <h1>The Cox<br />Global Network</h1>
                <p>A business degree's value is only as strong as the school's alumni network – and the SMU Cox Alumni Association is among the strongest you'll find.</p> 
                <p>A century in the making and nourished by the deep sense of connection that's core to Cox culture, our alumni association is close-knit, active and entrepreneurial. With more than 42,000 members and chapters in some 40+ countries around the world, wherever you go after graduation – from downtown Dallas to Shanghai – you’ll find fellow alumni ready to help.</p>
                <img src={discoverImage1a} className="discover-image-1-a" alt="" />
             </div>
             <div className="blue-box1">
                <img src={discoverImage1b} className="discover-image-1-b" alt="" />
                <img src={quote} alt="" className="quote" />
                <p>I chose SMU Cox because getting an MBA program is an investment, and I know I'll have the greatest return with this program. SMU Cox is well respected and has an outstanding alumni network, both globally and locally.</p>
                <h2><b>- Andre Goulborne</b><br />PMBA '16</h2>
             </div>
             <div className="grey-box2">
               <p>At the heart of Cox is a close community and a generous culture of giving back. One day you can pay forward the help you get now, and you and your peers will support one another in invaluable personal and professional ways throughout your careers.</p>
             </div>
           </div>
           <div className={`discover-content-2 discover-content-container`}  ref={discoverContent2}>
              <div className="grey-box1">
                <h1>Ally for<br/>business</h1>
                <p>Business ... is a battlefield. To succeed, you don’t just need a degree or certificate. You need an ALLY. A powerful strategic partner. One who has your back, shares your interests and helps you rise to every challenge. With internationally renowned faculty, ambitious peers and globally connected alumni – including over 500 CEOs, CFOs and board members in Texas, SMU Cox is your ally in business. And your ally for life.</p>
                <img src={discoverImage2a} className="discover-image-2-a" alt="" />
             </div>
             <div className="blue-box1">
               {/* <img src={quote} alt="" className="quote" /> */}
               <img src={discoverImage2b} className="discover-image-2-b" alt="" />
               {/* <p><br /><br /><br /></p> */}
             </div>
             <div className="grey-box2">
               {/* <p>At the heart of Cox is a close community and a generous culture of giving back. One day you can pay forward the help you get now, and you and your peers will support one another in invaluable personal and professional ways throughout your careers.</p> */}
             </div>
           </div>
           <div className={`discover-content-3 discover-content-container`}  ref={discoverContent3}>
              <div className="grey-box1">
                <h1>The entrepreneurial<br />spirit at SMU Cox</h1>
                <p>SMU Cox doesn’t just keep up with the latest business theories and trends – we define them. Innovation permeates everything we do, from the classroom to the boardroom and beyond. Motivated by their entrepreneurial spirit, our students and alumni go on to change the world.</p> 
                <p>Technology is evolving so quickly that today’s students, who are tomorrow’s leaders, have to be agile, adapt and adopt new techniques and applications to tackle grand challenges. At Cox, they apply what they have learned to make smart decisions in the marketplace.</p>
                <img src={discoverImage3a} className="discover-image-3-a" alt="" />
             </div>
             <div className="blue-box1">
                <img src={discoverImage3b} className="discover-image-3-b" alt="" />
                <img src={quote} alt="" className="quote" />
                <p>The next 50 years we will witness the rise of unimaginable new startup business models.  At the Cox School we have always encouraged a spirit of entrepreneurship and we will remain at the forefront of encouraging the next generation of student entrepreneurs.</p>
                <h2><b>- Simon Mak</b><br />Executive Director SMU Cox Caruth Institute for Entrepreneurship</h2>
             </div>
             <div className="grey-box2">
               <p>SMU Cox is dedicated to encouraging intellectual curiosity and drive to ensure that our graduates have technical capabilities, leadership competencies and skills that last a lifetime.</p>
             </div>
           </div>
         </div>
      </div>
  );
}

export default Discover;
import React, {} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import "./App.css";
import Admin from "./components/Admin/Admin";
import Site from "./components/Site/Site";
import RotateImage from './assets/images/rotate.png'

function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route path="/admin/" children={<Admin />} />
        <Route path="/:path" children={<Site />} />
        <Route path="/" children={<Site />} />
      </Switch>
      <div className="rotate">
          <img src={RotateImage} className="rotate-image" alt="Rotate Device" />
      </div>
    </Router>
    </>
  );
}

export default App;
import './TopControls.css';
import backButton from '../../assets/images/back-button.png';
import skipButton from '../../assets/images/skip-button.png';
import soundOn from '../../assets/images/speaker.png';
import soundOff from '../../assets/images/mute-icon.png';
import shareButton from '../../assets/images/share.png';

import React,{ useEffect, useRef, useState} from 'react';
import eventBus from "../EventBus";

import gsap from 'gsap';
import { Environment } from '@react-three/drei';

function TopControls() {

  const topControls = useRef();
  const muteButtonRef = useRef();
  const backButtonRef = useRef();
  const skipButtonRef = useRef();
  const shareButtonRef = useRef();

  const [soundIcon, setSoundIcon] = useState(soundOn);

  /////////////////////////////////////////////////////////////////////////////////
  
  useEffect(() => {
    window.soundOn=true;

    eventBus.on("showTopControls", (data) =>{

      
      //JAY
      // console.log("window.view = " + window.view, "window.returnPath = " + window.returnPath);

      // switch(window.returnPath)
      // {
      //     case "donors":
      //     case "gallery":
      //     case "dean":
      //     case "donor":
      //     case "success":
      //     case "key":
      //     case "discover":
          
      //       console.log("DENIED!");

      //       return;

      //     break;
          
      //     case "landing":
      //     case "rotunda":
      //     case "room1":
      //     case "room2":
      //     case "room3":
      //       //
      //     break;
      // }
      
      // console.log("show top controls", data)

      gsap.killTweensOf([topControls.current, skipButtonRef.current, backButtonRef.current, muteButtonRef.current, shareButtonRef.current]);

      const tl = gsap.timeline({paused:true,delay:0});
      tl.set(skipButtonRef.current,{
        autoAlpha:(data && data.showSkip)?1:0,
        display:(data && data.showSkip)?"block":"none"
      },0);
      tl.set(backButtonRef.current,{
        autoAlpha:(data && data.showBack)?1:0,
        display:(data && data.showBack)?"block":"none"
      },0);
      tl.set(muteButtonRef.current,{
        autoAlpha:(data && data.showMute)?1:0,
        display:(data && data.showMute)?"block":"none"
      },0);
      tl.set(shareButtonRef.current,{
        autoAlpha:(data && data.showShare)?1:0,
        display:(data && data.showShare)?"block":"none"
      },0);
      tl.fromTo(topControls.current,{autoAlpha:0,x:0},{autoAlpha:1, x:0, duration:.5},0);//(data.delay)?data.delay:0);
      tl.play();
    });

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.on("hideTopControls", (data) =>{
      // console.log("hide top controls")
      
      gsap.killTweensOf([topControls.current]);
      
      const tl = gsap.timeline({paused:true});
      tl.to(topControls.current,{x:0, autoAlpha:0,duration:.5},0);
      tl.play();
    });

  },[])

  /////////////////////////////////////////////////////////////////////////////////

  const backClicked = (e) =>{
    //alert('back clicked')
    //handle based on view and section
    if(window.section==="rotunda"){
      eventBus.dispatch("hideRotunda");
      eventBus.dispatch("showLanding");
      eventBus.dispatch("updateSideBarContent",{path:"landing",delay:3})
    }

    if(window.section==="room_1" || window.section==="room_2" || window.section==="room_3"){
      eventBus.dispatch("hideRoom",{});
      eventBus.dispatch("showRotunda",{delay:2});
      eventBus.dispatch("updateSideBarContent",{path:"rotunda",delay:3})
    }

    eventBus.dispatch("enableClickBlocker");

    const tl = gsap.timeline({paused:true});
    tl.call(()=>{
      eventBus.dispatch("disableClickBlocker");
    },null,3.5)
    tl.play();
  }

  /////////////////////////////////////////////////////////////////////////////////

  const muteClicked = (e) =>{
    //alert(window.soundOn)

    if( window.soundOn) { 
      window.soundOn = false;
      window.muted=true;
      window.Howler.mute(true);
      setSoundIcon(soundOff);

      setTimeout(()=>{

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'virtualPageView',
          'pageURL': 'mute',
          'username': (window.username) ? window.username : 'publicVisitor'
        });
  
        console.log("virtualPageView / mute");
        // console.log("virtualPageView / window.path = " + window.path);
  
      },100)

    } else {
      window.soundOn=true;
      window.muted=false;
      window.Howler.mute(false);
      setSoundIcon(soundOn);
    }

    eventBus.dispatch("muteVideo",{});
  }

  /////////////////////////////////////////////////////////////////////////////////

  const skipClicked = (e) =>{

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'skipIntroVideo',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / skipIntroVideo");
      // console.log("virtualPageView / window.path = " + window.path);

    },100)

    eventBus.dispatch("hideVideo",{});
    eventBus.dispatch("hideTopControls",{});
    eventBus.dispatch("enableClickBlocker");

    const tl = gsap.timeline({paused:true});
    tl.call(()=>{
      eventBus.dispatch("disableClickBlocker");
    },null,5)
    tl.play();
  }

  /////////////////////////////////////////////////////////////////////////////////

  const shareClicked = (e) =>{

    /////////////////////////////////////////////////////////////////////////////////

    setTimeout(()=>{

      window.dataLayer  = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualPageView',
        'pageURL': 'share',
        'username': (window.username) ? window.username : 'publicVisitor'
      });

      console.log("virtualPageView / share");

    },100)

    /////////////////////////////////////////////////////////////////////////////////

    eventBus.dispatch("learnMoreButtonClicked", {});
    eventBus.dispatch("enableClickBlocker");

    const tl = gsap.timeline({paused:true});
    tl.call(()=>{
      eventBus.dispatch("hideCTA");
      eventBus.dispatch("showSideBarBackButton")
      eventBus.dispatch("showShare");
    },null,2)
    tl.call(()=>{
      eventBus.dispatch("disableClickBlocker");
    },null,2.5)
    tl.play();
  }

  /////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="top-controls" ref={topControls}>
      <img ref={skipButtonRef} onClick={skipClicked}  className="top-controls-back" src={skipButton} alt="skip"/>
      <img ref={backButtonRef} onClick={backClicked}  className="top-controls-back" src={backButton} alt="back"/>
      <img ref={muteButtonRef} onClick={muteClicked}  className="top-controls-mute" src={soundIcon} alt="mute"/>
      <img ref={shareButtonRef} onClick={shareClicked}  className="top-controls-share" src={shareButton} alt="share"/>
    </div>
  );
}

export default TopControls;
// import icon from '../../assets/images/share.png';
import React,{ useEffect, useState} from 'react';
import AdminLogin from '../AdminLogin/AdminLogin';
import AdminTools from '../AdminTools/AdminTools';
// import eventBus from "../EventBus";
import './Admin.css';

function Admin() {



  // const [data, setData] = useState(null);

  const [showLogin, setShowLogin] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  

  useEffect(() => {

    let user = sessionStorage.getItem("user");
    //console.log("user",user);

    if(user) {
      setShowLogin(false);
      setShowAdmin(true);
    } else {
      setShowLogin(true);
      setShowAdmin(false);
    }
  }, []);

  return (
    <div className="admin">
      {showLogin && <AdminLogin />}
      {showAdmin && <AdminTools />}
      {/* <p>{!data ? "Loading..." : JSON.stringify(data.users[0])}</p> */}
    </div>
  );
}

export default Admin;
import React,{ Suspense, useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Enter from '../Enter/Enter';
import Video from '../Video/Video';
import Landing from '../Landing/Landing';
import SideBar from '../SideBar/SideBar';
import Loader from '../Loader/Loader';
import CTA from '../CTA/CTA';
import eventBus from "../EventBus";
import Share from '../Share/Share';
import Contact from '../Contact/Contact';
import Executive from '../Executive/Executive';
import Scene3D from '../Scene3D/Scene3D';
import './Site.css';
import Donors from '../Donors/Donors';
import Gallery from '../Gallery/Gallery';
import Dean from '../Dean/Dean';
import Donor from '../Donor/Donor';
import TopControls from '../TopControls/TopControls';
import Success from '../Success/Success';
import Discover from '../Discover/Discover';
import Key from '../Key/Key';
import Blocker from '../Blocker/Blocker';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Site() {

  let { path } = useParams();
  let query = useQuery();
  let code = query.get("code");

  useEffect(() => {
    window.unlocked = false;
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    
    window.code = (code) ? code : '';
    window.path = ( path ) ? path : '';
    
    // setTimeout(()=>{
    //   console.log("window.path = " + window.path);
    // },200)

    window.showRoom1 = true;
    window.showRoom2 = true;
    window.showRoom3 = true;

    fetch("/api/settings")
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);

      // check public site settings first
      window.showRoom1 = data.settings[0].room1;
      window.showRoom2 = data.settings[0].room2;
      window.showRoom3 = data.settings[0].room3;

      // now override settings related to custom url
      if(window.code) { 
        // console.log("custom code", code) 
        let d = window.atob(code).split("_");
        // console.log(d);
        window.username = d[0];
        window.showRoom1 = (d[1][0] === '1');
        window.showRoom2 = (d[1][1] === '1');
        window.showRoom3 = (d[1][2] === '1');
      };
    })

    setTimeout(()=>{
      // if(window.path) { 
      //   switch(window.path){
      //     case "landing":
      //       eventBus.dispatch("showLanding",{path:path});
      //       break;
      //     case "share":
      //       eventBus.dispatch("showLanding",{path:path});
      //       eventBus.dispatch("learnMoreButtonClicked",{path:path})
      //       eventBus.dispatch("showShare",{path:path});
      //       break;
      //     case "contact":
      //       break;
      //     case "executive":
      //       break;
      //     case "rotunda":
      //       eventBus.dispatch("showLanding", {});
      //       eventBus.dispatch("hideLanding", {});
      //       eventBus.dispatch("showRotunda", {});

      //       eventBus.dispatch("hideLandingEnter", {});
      //       eventBus.dispatch("updateSideBarContent", {path:"rotunda",delay:4});
      //       break;
      //     default:
      //       eventBus.dispatch("showEnter",{path:path});
      //       break;
      //   }
      //   // handle any special steps for deep links
      //   console.log("Process Deep link", path);
      //   eventBus.dispatch("hideLoader",{delay:1});
      
      // } else {
      //   // handle regular site load
        console.log("process root link")

        if(window.code)
        {
          // console.log('./?code=' + code);
          window.history.pushState({},'','./?code=' + code);
        }
        else
        {
          window.history.pushState({},'','./');
        }

        eventBus.dispatch("showEnter",{path:path, delay:3});
        eventBus.dispatch("hideLoader",{delay:3}); 
      // };
    },500)
   
  }, [])

  return (
    <>
      <Loader />
      <Enter  />
      <Suspense fallback={null}>
        <Scene3D />
      </Suspense>
      <Video />
      <Landing />
      <SideBar />
      <CTA />
      <Share />
      <Contact />
      <Donor />
      <TopControls /> 
      <Dean />
      <Success />
      <Gallery />
      <Donors />
      <Executive />
      <Discover />
      <Key />
      <Blocker />
    </>
  );
}

export default Site;